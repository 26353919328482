import React from "react";
import Button from "../../components/Button";
import ContentRight from "../../components/ContentRight";
import MetaTags from "react-meta-tags";


function Services() {

    return (
        <div className="content_wrapper">
            <MetaTags>
                <title>Services</title>
                <meta id="meta-description" name="description" content="Cohabitation Agreement UK offers the fastest online service, a straightforward questionnaire, FREE advice line, FREE e-books and solicitor consultation. " />
                <meta id="meta-keywords" name="keywords" content="Cohabitation Agreement, DIY Agreement, Quick Cohabitation, Cohabitation Agreement Services, Fast Cohabitation, Cohabitation Online, Simple Cohabitation, Cohabitation  Advice, Cheap Agreement " />
                <meta id="og-title" property="og:title" content="Services" />
            </MetaTags>
            <div className="main_left col-md-9 col-sm-12">
                <h1>Services</h1>

                <p>No other online Pre-nuptial Agreement service allows you the chance to compare quality or value
                    for money and ultimately get the lowest cost, highest performance deal.</p>


                <hr />

                <h3>Personalised Service £67</h3>

                <p>No other online cohabitation agreement service provides you with a comparable quality or
                    value for money service. For customers who do not feel the need for any solicitor
                    involvement we offer our Personalised Service for just £67. </p>

                <p>The personalised service offers <b>FREE Support</b> from our experienced staff. They can
                    provide help and advice on any related subject via email or over the phone. <b>FREE
                        Updates</b> allow customers notification of law changes or requirements that may affect
                    their current agreement. </p>

                <p>We also offer customers a <b>FREE Guide</b> - The Essential Guide to Cohabitation Agreements
                    (worth £19.99) to ensure full understanding. Due to the huge number of people who overlook
                    this essential piece of documentation when they move in with a new partner we are currently
                    offering a <b>Discounted Will</b> for the reduced price of £10 (usually £39) when you
                    purchase any Cohabitation Agreement service.</p>

                <div className="index_btn1">
                    <Button imageName="order_now3a" imagePath="images/BTN-order-now.gif"
                            imagePathRestore="images/BTN-order-now-over.gif"
                            alt="Order Now" to="ordernow.html"/>
                </div>

                    <a href="personal-service.html">
                        <div className="index_btn2"></div>
                    </a>
                </div>
            <ContentRight />
        </div>
    );
}

export default Services;