import React from "react";
import ContentRight from "../../components/ContentRight";


function Article8() {
    return (
        <div className="content_wrapper">
            <div className="main_left col-md-9 col-sm-12">
                <h1>Pros and Cons of Cohabitation</h1>

                <h3>Pros</h3>

                <ul>
                    <li><b>Next logical step</b>
                        <p>In a serious relationship you will reach a point at which you begin to feel that you
                            should take the next step towards validating the growth of your relationship. In today’s
                            day and age, where living together without entering into a legal marriage is not even
                            blinked at, moving in together is seen to be the next step on from dating in the
                            progression of a relationship.</p>
                    </li>
                    <li><b>Chance to get to Know Your Partner</b>
                        <p>Living together is now seen as a “trial run” before getting married. It provides a chance
                            to get to know each other’s living habits before you make the extra commitment and
                            become a married couple. The thought is that if a couple spends some time living
                            together before marriage they will know whether or not the relationship can go the
                            distance before they make the commitment to marry and, in theory, they will not get any
                            nasty suprises. </p>
                    </li>
                    <li><b>Get to spend more time with the person you love</b>
                        <p>When in a loving relationship, especially during the early stages of a relationship, a
                            couple often wants to spend as much time together as possible. Living together can give
                            you a chance to have meals together, share a bed and snuggle up on cosy nights in – all
                            of which are desirable prospects when you are sharing your life with someone you
                            love.</p>
                    </li>
                    <li><b>Financial Benefits</b>
                        <p>Moving in together can often be with motivation to make a monetary saving. Two single
                            people living alone pay two separate sets of bills, rent or mortage and general living
                            costs. Living with a partner you are enjoying a relationship with can be an attractive
                            prospect when the impact it would have on monthly expenses is considered. Usually one
                            partner would move into the home of the other partner. This could mean just one rent
                            payment or mortgage payment a month, or if both partners own their own properties, one
                            could rent out their property thus formulating another source of income. Individuals may
                            also be able to save money that they spent during dating, on fuel and phone bills. </p>
                    </li>
                    <li><b>Learn about other person resopnsibility</b>
                        <p>As well as the general living habits of your partner, living together can allow you an
                            insight into your partners sense of responsibility. If two people have totally different
                            views and attitudes towards the level of responsibility one should uphold in general
                            day-to-day life, this can have a significant bearing of the success of the relationship.
                            For example, one partner who is meticulous about paying bills on time may have trouble
                            comprehending or accepting a relaxed attitude. Living together can give you a chance to
                            realise these differences and make a decision on whether or not you can live with these
                            before marriage.</p>
                    </li>
                    <li><b>Freedom to easily leave</b>
                        <p>Having got to know the person you are in a relationship with, you come to realise whether
                            or not this is the long term relationship that you want to continue in. Cohabiting
                            couples have the freedom to leave at any point without the rigmarol or stress of
                            divorce.</p>
                    </li>
                </ul>

                <h3>Cons</h3>

                <ul>
                    <li><b>May get sick of each other</b>
                        <p>During the “honeymoon” period moving in together may feel like a tempting suggestion but
                            spending too much time together can take its toll and progress to feelings of
                            suffocation. Otherwise potentially successful relationships can self destruct if
                            progression is forced too early. </p>
                    </li>
                    <li><b>Expect marriage</b>
                        <p>Moving in together for many people, just means a step closer to marriage. This can lead
                            to disappointment when the steps towards marriage don’t happen. Some people can be
                            satisfied with cohabiting if they feel that the relationship is working and progressing
                            in a way which they are comfortable with.</p>
                    </li>
                    <li><b>Won’t find out everything</b>
                        <p>Even though living together does demonstrate the general living habits of a partner, it
                            cannot give you a full insight into their character if they are resistant in revealing
                            it. Some couples can be together for many years before they feel that they have seen
                            their partner’s true colours and marriages can break down after many decades. Therefore,
                            it can be argued that cohabitation before marriage is pointless.</p>
                    </li>
                    <li><b>Lessens excitement of marriage</b>
                        <p>Many still respect the sanctity of marriage and hold the matrimonials in high regard.
                            Living together can be fun but when you enter into marriage you will have a ring on your
                            finger and a change of name but what else will you have gained? The excitement of
                            marriage can be reduced when you realise that after the big day you will be going back
                            to the same home and the same life you lived prior to getting married.</p>
                    </li>
                </ul>
            </div>
            <ContentRight/>
        </div>
    );
}

export default Article8;