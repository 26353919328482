import React from "react";
import ContentRight from "../../components/ContentRight";


function Article12() {
    return (
        <div className="content_wrapper">
            <div className="main_left col-md-9 col-sm-12">

                <h1>Pre-nuptial Agreements Around the World</h1>

                <p>The law changes, depending where you are in the world. A prenuptial agreement may hold weight in
                    one country but not the next. So it is important you know how the law of your country
                    understands prenuptial agreements before you take one out. The following list of countries
                    details the different law enforcements regarding prenuptial agreements.</p>

                <h3>England</h3>

                <p>In England, the courts in the past have given little weight to prenuptial agreements because it
                    was believed they went against public policy. However, today this view is shifting. English
                    courts are now stating that a pre-nuptial agreement can be taken into consideration when
                    deciding what adjustments to make to divorcing couple's financial circumstances. Indications,
                    based on previous cases, suggest that where there is no duress, the parties have received
                    independent legal advice, the relevant facts have been disclosed and the agreement is not
                    manifestly unfair, the courts are increasingly likely to uphold the terms of a pre-nuptial
                    agreement.</p>

                <h3>Australia</h3>

                <p>In Australia, prenuptial agreements are known as 'binding financial agreements' and became
                    enforceable in Australia with the enactment of the Family Law Amendment Act 2000.</p>

                <p>Australia's law states that for a binding financial agreement to be binding it must be in writing
                    signed by both parties.</p>

                <p>An agreement will not be binding if:</p>

                <ul>
                    <li>It was obtained by fraud, was made under duress, by mistake, by virtue of undue influence,
                        or if it is impracticable for all or part of the agreement to be carried out.
                    </li>
                    <li>If there has been a material change in the cure of a child leading to hardship</li>
                    <li>If a party engaged is in unconscionable conduct when making the agreement such as where one
                        spouse is at a disadvantage, the other spouse knows it and the agreement runs contrary to
                        good conscience.
                    </li>
                </ul>

                <h3>Austria</h3>

                <p>Austria is a party to the Hague Convention on the law applicable to matrimonial property regimes
                    which specifically authorise pre-nuptial agreements.</p>

                <h3>Brazil</h3>

                <p>Pre-nuptial Agreements are enforceable.</p>

                <h3>Canada</h3>

                <p>Prenuptial agreements are enforceable in Canadian Courts in Ontario. Other common law provinces
                    of Canada previously considered marriage contracts to be contrary to public policy and
                    unenforceable, but the 1978 Family Law Reform Act specifically authorised marriage
                    contracts.</p>

                <p>The Family Law Act provides that a court may set aside a provision for support or a waiver of the
                    right to support in a marriage contract and may determine and order support even though the
                    contract contains an express provision excluding the application of this section.</p>

                <ul>
                    <li>If the provision for support or the waiver of the right to support results in unconscionable
                        circumstances
                    </li>
                    <li>If the provision for support is in favour of, or the waiver is by or on behalf of a
                        dependant who qualifies for allowance for support out of public money.
                    </li>
                    <li>If there is default in the payment of support under the contract or agreement at the time
                        the application is made.
                    </li>
                </ul>

                <p>As a result, a provision in the marriage contract either limiting or precluding a claim for
                    future support is very much subject to the discretion of the court at the time an application
                    for support is made</p>

                <h3>China</h3>

                <p>Article 19 of the 2001 Marriage Law specifies that:</p>

                <p>'So far as the property acquired during the period in which they are under contract of marriage
                    and the pre-nuptial property are concerned, husband and wife may agree as to whether they should
                    be in the separate possession, joint possession, or partly separate possession or partly joint
                    possession. The agreement shall be made in writing. The provisions of Articles 17 and 18 of this
                    law shall apply to the absence of such an agreement or to a vague one.</p>

                <h3>Finland</h3>

                <p>Pre-nuptial agreements are enforced in Finland. A new law applies in Finland which allows the
                    spouses to decide in advance which law will govern their marriage, provided that at least one of
                    the spouses has a connection based on nationality or domicile to the state whose law they want
                    to apply.</p>

                <h3>Germany</h3>

                <p>Pre-nuptial agreements are enforceable. Germany's Federal Court of Justice recently ruled that
                    notarized pre-nuptial agreements that seriously disadvantage one party in a marriage could be
                    deemed invalid. The judges stated that while, in principle, a contract may state that one of the
                    partners has renounced his or her right to receive alimony. If the agreement is one sided it
                    would be morally unacceptable and could therefore be challenged. The court also ruled that a
                    spouse is free to contest the contract in instances of imbalance where her partner's income has
                    risen dramatically during the marriage because, for example, she was home caring for
                    children.</p>

                <h3>Greece</h3>

                <p>Pre-nuptial agreements are enforceable.</p>

                <h3>Ireland</h3>

                <p>In Ireland it appears that the courts are not required to enforce pre-nuptial agreements. The
                    Family Law Act (1996) gives the Irish courts extremely wide discretion over the distribution of
                    a divorcing couples assets.</p>

                <p>It is assumed that Irish courts will not consider and will most certainly refuse to automatically
                    enforce a pre-nuptial agreement.</p>

                <h3>Jamaica</h3>

                <p>It is believed that a case concerning pre-nuptial agreements has not yet been before the courts
                    in Jamaica. Traditionally Jamaica has followed English law.</p>

                <h3>Japan</h3>

                <p>In Japan, the Horei Law authorises spouses who marry in Japan to choose which matrimonial law
                    regime will govern their marriage, provided it is the law of the country of either spouses or
                    nationality or habitual residence, or, regarding immovable's, the law of the location of the
                    immovable's.</p>

                <p>The Horei Law also specifies that pre-nuptial agreements are valid when made under the provisions
                    of a foreign law and sets forth a provision for registration of foreign pre-nuptial agreements
                    in Japan.</p>

                <h3>Luxemburg</h3>

                <p>Luxemburg is a party to the Hague Convention on the law applicable to matrimonial property
                    regimes, which specifically authorises pre-nuptial agreements.</p>

                <h3>Netherlands</h3>

                <p>The parties may enter into a pre-nuptial agreement at the time of concluding their marriage
                    or/and during the marriage itself, but in the latter case, the approval of the courts is
                    required.</p>

                <h3>New Zealand</h3>

                <p>Section 21 of New Zealand's property (relationships) Act 1976 expressly authorizes spouses to
                    make agreements.</p>

                <h3>Philippines </h3>

                <p>The law of the Philippines allows spouses to execute and file with the Civil Registry a
                    prenuptial Property Agreement (Surat Pernyataan Harta) which must be signed before a local
                    notary public. Otherwise, Indonesian marriage law assumes joint ownership of property.</p>

                <h3>Portugal </h3>

                <p>Prenuptial agreements are enforceable. Portugal is a party to the Hague Convention on the Law
                    Applicable to Matrimonial Property Regimes, which specifically authorizes prenuptial
                    agreements.</p>

                <h3>Russia </h3>

                <p>Prenuptial agreements are enforceable. </p>

                <h3>South Africa </h3>

                <p>Prenuptial agreements are enforceable.</p>

                <h3>Spain </h3>

                <p>Prenuptial agreements are enforced in Spain, unless they should be detrimental to the children or
                    seriously damaging to one of the spouses (Article 90, Spanish Civil Code). In recent years,
                    there has been a large increase in the number of prenuptial agreements signed in Spain. </p>

                <h3>Sweden </h3>

                <p>Prenuptial agreements are enforced in Sweden.</p>

                <h3>Switzerland </h3>

                <p>Prenuptial agreements are enforceable in Switzerland. Prior to a marriage abroad, you must
                    consult the private international law of the chosen country to find out the conditions to be met
                    and the applicable judicial regulations. This informed outlook will help you choose a solution
                    that best suits your needs. In fact, the applicable matrimonial regime will be the one you will
                    have chosen (written agreement, marriage contract). You may choose between the law of the
                    country in which both of you are residing and the law of the country of which one of you is a
                    citizen. You may modify your selection at any time. In general, if you have not expressed a
                    choice, the law of the country of residence applies. </p>

                <h3>Thailand </h3>

                <p>Prenuptial agreements are enforced in Thailand.</p>
            </div>
            <ContentRight/>
        </div>
    );
}

export default Article12;