import React from "react";
import ContentRight from "../../components/ContentRight";
import MetaTags from "react-meta-tags";


function Support() {
    return (
        <div className="content_wrapper text-center support-box">
            <MetaTags>
                <title>Support</title>
                <meta id="meta-description" name="description" content="Our contact details to contact us should you have any queries" />
                <meta id="meta-keywords" name="keywords" content="Cohabitation Agreement, DIY Agreement, Quick Cohabitation, Sitemap, Online Cohabitation Agreement, Agreement Advice, Cheap Cohabitation Agreement, cohabitation, support, contact us, telephone, fax, email" />
                <meta id="og-title" property="og:title" content="Support" />
            </MetaTags>
            <div className="main_left col-md-9 col-sm-12">
                    <h1>Confused about your Cohabitation Agreement?</h1>

                    <p className="cb">Last year, over 22,500 people in the UK called us for help and advice initiating
                        their Cohabitation Agreement.</p>

                    <p>Our team of experts can give guidance on all matters relating to our Cohabitation Agreement
                        process, and can also help you choose which service is right for you.</p>

                    <p className="support_hdr">For free, impartial advice – call us now on free phone:</p>
                    <p className="support_telno">0800 440 2511</p>
                    <hr />
                        <div className="support_l">
                            <p className="support_hdr2">Email us:</p>
                        </div>
                        <div className="support_r">
                            <p className="support_email">General Enquiries:<br /><a
                                href="mailto:enquiries@cohabitation-agreement.co.uk">enquiries@cohabitation-agreement.co.uk</a>
                            </p>

                            <p className="support_email">Customer Support:<br /><a
                                href="mailto:                 support@cohabitation-agreement.co.uk"> support@cohabitation-agreement.co.uk</a>
                            </p>

                            <p className="support_email">Comments &amp; Suggestions:<br /><a
                                href="mailto:                 webmaster@cohabitation-agreement.co.uk"> webmaster@cohabitation-agreement.co.uk</a>
                            </p>

                        </div>
                        <div className="support_l">
                            <p className="support_hdr2">Fax us:</p>
                        </div>
                        <div className="support_r">
                            <p className="support_faxno">01656 669 524</p>
                        </div>
                        <div className="support_l">
                            <p className="support_hdr2">Write to us:</p>
                        </div>
                        <div className="support_r">
                            <p className="support_email">Cohabitation Agreement UK<br />
                                Fields House<br />
                                12/13 Old Fields Road<br />
                                Pencoed<br />
                                Bridgend<br />
                                CF35 5LJ
                            </p>
                        </div>
                        <div className="support_l">
                            <p className="support_hdr2">Submit feedback:</p>
                        </div>
                        <div className="support_r">
                            <p className="support_email"><a href="contact.html">Click here to contact us</a></p>
                        </div>
            </div>
            <ContentRight/>
        </div>
    );
}

export default Support;