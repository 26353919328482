import React from "react";
import ContentRight from "../../components/ContentRight";
import MetaTags from "react-meta-tags";


function PrivacyPolicy() {

    return (
        <div className="content_wrapper">
            <MetaTags>
                <title>Personalised service</title>
                <meta id="meta-description" name="description" content="Our personalised service provides customers with a unique, tailored Cohabitation Agreement" />
                <meta id="meta-keywords" name="keywords" content="Cohabitation Agreement, Personalised Service, Quick Cohabitation, FREE Cohabitation Advice, Online Cohabitation, Cohabitation Agreement Experts, Cohabitation Agreement Advice, Cohabitation Agreement Information" />
                <meta id="og-title" property="og:title" content="Personalised service" />
            </MetaTags>
            <div className="main_left col-md-9 col-sm-12">
                <div className="main_left">
                    <h1>Privacy Policy</h1>

                    <div className="box22">

                        <img src="images/badges2.png" className="guarantee_image_1" alt="privacy policy" />

                            <p>Cohabitation Agreement UK is committed to protecting the identity and privacy of our
                                users.</p>

                            <p>We will only use information collected in the process of transactions in a strictly
                                lawful manner.</p>

                            <p>Cohabitation Agreement UK will not use client emails to contact clients once the
                                transaction has been completed other than in accordance with a query from them. *</p>

                            <p>Email addresses will not be provided to any third party.</p>

                            <p>Users should be aware that Cohabitation Agreement UK retains no information relating to
                                clients' credit card details. This information is never known to Cohabitation Agreement
                                UK and is only retained by the Merchant Service Provider.</p>

                    </div>

                    <p id="note">* Clients may receive emails regarding related products or
                        services they may find of
                        interest.</p>
                </div>
            </div>
            <ContentRight/>
        </div>
    );
}

export default PrivacyPolicy;