import React from "react";
import Button from "../../components/Button";
import ContentRight from "../../components/ContentRight";
import MetaTags from "react-meta-tags";


function Guarantee() {

    return (
        <div className="content_wrapper">
            <MetaTags>
                <title>Guarantee</title>
                <meta id="meta-description" name="description" content="We believe we have developed the quickest, simplest, best quality Separation solution available in the UK" />
                <meta id="meta-keywords" name="keywords" content="Separation Agreement, About Separation Agreement, Solicitor Separation Agreement, Quick Separation Agreement, Online Agreement, Separation Advice, Separation Agreement Information, Apply Online" />
                <meta id="og-title" property="og:title" content="Guarantee" />
                <meta id="og-image" property="og:image" content="../images/couple.fc414c90.jpg"/>
            </MetaTags>
            <div className="main_left col-md-9 col-sm-12">
                <h1>Our Guarantee</h1>

                <p><b>We want you to be 100% happy or 100% refunded!</b></p>

                <div className="box22">

                    <img src="/images/badges.png" className="guarantee_image_1" alt="guarantee badges" />

                        <p>We believe we have developed the quickest, simplest, best quality cohabitation agreement
                            available in the UK.</p>

                        <p>However, we realise that buying on the Internet can make some people feel a bit
                            uneasy.</p>

                        <p>That's why we offer a no-quibble guarantee that completely eliminates any risk, fear or
                            uncertainty you may feel.</p>

                        <p>Therefore, if, within 30 days of purchase, you find another online cohabitation agreement
                            product that offers the same quality of service, documentation and support as ours, for
                            less money, we will give you a <b>no-quibble 100% refund GUARANTEED</b>.</p>

                </div>

                <div className="index_btn1">
                    <Button imageName="order_now3a" imagePath="images/BTN-order-now.gif"
                            imagePathRestore="images/BTN-order-now-over.gif"
                            alt="Order Now" to="order_now.html"/>
                </div>
                <a href="services.html">
                    <div className="index_btn2"></div>
                </a>
            </div>
            <ContentRight/>
        </div>
        );
    }

export default Guarantee;