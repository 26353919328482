import React from "react";
import Button from "../../components/Button";
import ContentRight from "../../components/ContentRight";
import MetaTags from "react-meta-tags";


function Wills() {
    return (
        <div className="content_wrapper">
            <MetaTags>
                <title>Discounted Will</title>
                <meta id="meta-description" name="description" content="Purchase a Separation Agreement NOW and you'll receive your completed Last Will and Testament at an EXCLUSIVE Special Offer price of £10 (usually £39)" />
                <meta id="meta-keywords" name="keywords" content="Separation Agreement, About Separation Agreement, Solicitor Separation Agreement, Quick Separation Agreement, Online Agreement, Separation Advice, Separation Agreement Information, Apply Online, will, free" />
                <meta id="og-title" property="og:title" content="Discounted Will" />
                <meta id="og-image" property="og:image" content="../images/Main-Banner.jpg" />
            </MetaTags>
            <div className="main_left col-md-9 col-sm-12">
                <h1>Discounted Will</h1>

                <p>Is your partner featured in your Will? Do you even have a Will?</p>

                <p>Purchase a Cohabitation Agreement <b>NOW</b> and you'll receive your completed Last Will and
                    Testament at an <b>EXCLUSIVE Special Offer</b> price of £10 (usually £39). This quick, simple
                    and affordable Will solution allows you to change your Will whenever you want, and as many times
                    as you require, <b>COMPLETELY FREE</b>.</p>

                <p>If you are expecting a significant change in lifestyle you will probably want to make a new Will.
                    Your Last Will and Testament is a vital legal document, so it is important that your Will is
                    prepared correctly. </p>

                <p>Often, during an exciting change of circumstances the thought of changing the details in your
                    Will can be the furthest thing from your mind. If you want to change the details of your Will
                    now is the time to do it. </p>

                <p>Without a Will, in the event of anything happening to you, your spouse might not get anything
                    even though you live together because the law does not look upon cohabiting couples in the same
                    light as married couples. </p>

                <p>Make sure have <b>protected you assets and secured the future</b> for your loved ones if the
                    worst case scenario ever happened, by having a legally binding Will drafted with us at the
                    discounted cost of just £10.</p>
                <div className="index_btn1">
                    <Button imageName="order_now3a" imagePath="images/BTN-order-now.gif"
                            imagePathRestore="images/BTN-order-now-over.gif"
                            alt="Order Now" to="ordernow.html"/>
                </div>
                <a href="personal-service.html">
                    <div className="index_btn2"></div>
                </a>
            </div>
            <ContentRight/>
        </div>
    );
}

export default Wills;