import React from "react";
import Button from "../../components/Button";
import ContentRight from "../../components/ContentRight";
import MetaTags from "react-meta-tags";


function FreeEBook() {
    return (
        <div className="content_wrapper">
            <MetaTags>
                <title>FREE eBook</title>
                <meta id="meta-description" name="description" content="The Essential Guide to Cohabitation Agreements"/>
                <meta id="meta-keywords" name="keywords"
                      content="Cohabitation Agreement, DIY Agreement, Quick Cohabitation, Sitemap, Online Cohabitation Agreement, Agreement Advice, Cheap Cohabitation Agreement, Cohabitation, free, ebook, book"/>
                <meta id="og-title" property="og:title" content="FREE eBook"/>
                <meta id="og-image" property="og:image" content="../images/couple.fc414c90.jpg"/>
            </MetaTags>
            <div className="main_left col-md-9 col-sm-12">
                <h1>FREE eBook</h1>

                <p>You may not realise this but living together does not give you the same rights as a married
                    couple. Therefore, both your futures can be at risk. If the worst was to happen (i.e. death of a
                    spouse) then the survivor is not legally entitled to have any say over what happens to the other
                    partner’s finances, possessions or even the property you lived in. Regardless of how long you
                    have been together.</p>

                <p>You can fully understand how to protect yourself and those you love most, simply by reading this
                    comprehensive eGuide. This advice-filled guide covers every aspect of cohabitation agreements
                    including:</p>

                <table width="100%" border="0" cellSpacing="0" cellPadding="0" >
                    <tbody>
                    <tr>
                        <td>
                            <p align="justify"><strong>The Essential Guide to Cohabitation Agreements</strong> Jayne
                                McGill ©2005</p>
                            <ul>
                                <li><i>"The 6 Golden Rules of Cohabitation Agreements"</i></li>
                                <li>What will happen to the house you live in</li>
                                <li>Who should organise and pay for any home improvements</li>
                                <li>How will your possessions and assets be divided</li>
                                <li>Who should pay the mortgage</li>
                                <li>What will happen to joint accounts</li>
                                <li>Who will be responsible for clearing debts</li>
                                <li>And much, much more…</li>
                            </ul>
                        </td>
                        <td valign="top"><img src="/images/Book.png" border="0"
                                              alt="book" />
                        </td>
                    </tr>
                    </tbody>
                </table>

                <p>This is an exclusive offer from Cohabitation Agreement UK for immediate download to your
                    desktop <b>ABSOLUTELY FREE</b> when you buy a Cohabitation Agreement.</p>


                <div className="index_btn1">
                    <Button imageName="order_now3a" imagePath="images/BTN-order-now.gif"
                            imagePathRestore="images/BTN-order-now-over.gif"
                            alt="Order Now" to="ordernow.html"/>
                </div>

                <a href="services.html">
                    <div className="index_btn2"></div>
                </a>
            </div>
            <ContentRight/>
        </div>
    );
}

export default FreeEBook;