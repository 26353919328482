import React from "react";
import ContentRight from "../../components/ContentRight";


function Article() {
    return (
        <div className="content_wrapper">
            <div className="main_left col-md-9 col-sm-12">
                <h1>Cohabiting Across the World</h1>

                <h3>Ireland</h3>

                <p>During the last ten years Ireland has seen a 300% rise in the number of cohabiting couples.
                    58,000 couples in 1996 were recorded as living together in Ireland and that rose to 228,000 in
                    2006. Despite popular belief there is no such thing as a Common Law Marriage and cohabiting
                    couples are recommended to enter into a cohabitation agreement. Many couples enter a relaxed
                    arrangement where they split bills and living costs but often tenancy agreements and mortgages
                    will be in one partner’s name. Bearing in mind that 80% of cohabiting relationships break up
                    these arrangements can often leave one person losing out after a break up as they have not
                    officially invested anything into the home. </p>

                <p>Cohabiting is a far more cost effective alternative to marriage which has had a great
                    significance in the number of couples moving in together. Living cost, rent and mortgage
                    payments can all be split two ways whilst couples save on phone bills and fuel costs to visit
                    one another. With marriage costing an average of £40,000 cohabiting can be a good alternative
                    for the long term or help save towards a marriage in the short term. </p>

                <h3>USA</h3>

                <p>The marriage rate in the US has plunged by 80% since 1970 and this is partially blamed on the
                    relaxed attitude towards couples cohabiting without marriage. There were 4.1 million couples
                    cohabiting in 1997 which was a huge increase since the 430,000 in 1960. 1.6 million of the total
                    number of cohabiting couples are aged 24-35, With 931,000 under the age of 24, 60% of cohabiting
                    couples have never been married before and 13% don’t ever expect to marry.</p>

                <p> Celebrity couples are also setting a trend whilst more and more Hollywood stars are choosing not
                    to walk down the aisle. Parents and previously married Brad Pitt and Angelina Jolie are living
                    together without marriage, possibly due to the failures of their past marriages. Other celebrity
                    cohabiting couples include Halle Berry and Gabriel Aubrey, Goldie Hawn and Kurt Russell and Tim
                    Robbins and Susan Sarandon. </p>

                <p>Cohabiting is illegal in 12 USA states but the law is difficult to uphold and is rarely acted
                    upon. The reasons for having the law in place may not be obvious to everyone but with 62% of
                    couples cohabiting before marriage and half of these marriages ending in divorce within ten
                    years, the law may not be such a bad idea. </p>

                <p>Research has shown that there are many pros and cons within the relationship of a cohabiting
                    couple. It has been found that when compared to other couples, cohabiting couples:</p>

                <ul>
                    <li>Are more faithful</li>
                    <li>Fight more</li>
                    <li>Have higher levels of conflict or abuse</li>
                    <li>Have highest rates of severe domestic abuse</li>
                    <li>Are estranged from family members</li>
                </ul>

                <p>The results for faithfulness amongst cohabiting men were particularly poignant. 68.6% of
                    cohabiting couples have had at least one other sexual partner during the previous year compared
                    to 95.8% of husbands.</p>

                <p>The amount of sexual intercourse that a couple has appears to reduce in married couples too.
                    Cohabiting couples claim to have sex an average of 7.4 times a month whereas married couples
                    only 6.8 times a month. </p>
            </div>
            <ContentRight/>
        </div>
    );
}

export default Article;