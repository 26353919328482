import React from "react";
import ContentRight from "../../components/ContentRight";


function Article5() {
    return (
        <div className="content_wrapper">
            <div className="main_left col-md-9 col-sm-12">
                    <h1>Cohabiting – An overview</h1>

                    <p>You and your partner may live together for years, taking it for granted that you are not married
                        and have therefore simplified the situation for yourselves should anything go wrong. You bought
                        the house together but you will just divide the proceeds fairly if you were to split,
                        right? </p>

                    <p>WRONG – when you have just endured what is likely to become a hostile break up, who can determine
                        what “fairly” truly is? After a drawn out legal procedure you may end up with just 35% of the
                        proceeds from your home. Most will be thinking that’s unreasonable but for those few who are
                        thinking that’s a realistic split if your partner did contribute more than you did towards the
                        home, we would like to remind you that the previously mentioned legal procedure will have landed
                        you with a hefty bill. Court proceedings during a lengthy legal battle over assets could set you
                        back as much as £100.00 regardless of whether or not you just lost out on 15% of the house you
                        paid 50% towards. </p>

                    <p>All this palaver could have been avoided if you had just set your emotions aside when you were
                        caught up with those honey-moon-period-highs and been practical for a moment. Setting a few
                        minutes aside when you packed up your boxes to discuss the destiny of your assets should the
                        unthinkable occur could save you a lot of time in the future. It may seem callous to discuss a
                        break up when you are just starting something new and exciting, but once you have set out the
                        agreement, you can continue with your new experience and set the legal aspects of sharing a home
                        to the back of your mind. </p>

                    <p>Over 4,000,000 people in the UK live together without being married but the law is not adapted to
                        suit these people. There are ways in which you can help to make things easier should things go
                        pear shaped without affecting your day to day relationship inside your home. There are two types
                        of tenancy to choose from when buying a joint home.</p>

                    <p>You can opt for Joint Tenancy which means that you will both own the property jointly and will be
                        equally sharing it, leaving the other person in line to inherit the property should one party
                        pass away. The second option which is probably advisable for cohabiting couples is to buy as
                        Tenants in Common. This enables the parties to own shares in the property. You can split the
                        property 50:50, owning half each or in other denominations (e.g. 25:75) if you would prefer.
                        This leaves each party open to leave their share of the property to whomever they wish in their
                        will and in the event of a break up one party could buy out the other persons share. </p>

                    <p>A Cohabitation Agreement is a cheap and easy way to back up any precautions taken to protect your
                        assets when moving in together. Some people are mislead into thinking that the agreement process
                        is complex and requires confusing legal jargon but this is simply not the case. Advice from a
                        professional is available cheaply and once you have laid out all of your assets on paper it is
                        simply a case of clarifying that you will keep your rights in the event of a split. </p>

                    <p>Cohabitation Agreements have recently become a trend amongst non-couples looking to purchase
                        property together too. With the current economic climate making it more and more difficult for
                        first time buyers to get on the property ladder, friends and relatives are looking to each other
                        for help securing a position within the property market. A Cohabitation Agreement is easier to
                        enter into when you are in this more practical position of joint purchasing and an ideal legal
                        reassurance, especially when available at such a low cost. </p>

                    <p>A cohabitation agreement is legally binding, but the law still needs reforming to cater for
                        cohabiting couples and professional partnerships. It is recommended that each party seek their
                        own cohabitation agreement and sign each other’s in order to prevent any undue duress later on.
                        Legal experts also recommend that when entering into ownership of a property, individuals should
                        make a Will or update their existing Will, this is even more imperative when entering into a
                        joint ownership. The Will can backup anything in your Cohabitation Agreement and ensure that if
                        the worst should happen your loved ones will be looked after. </p>
            </div>
            <ContentRight />
        </div>
    );
}

export default Article5;