import React from "react";
import ContentRight from "../../components/ContentRight";


function Article2() {
    return (
        <div className="content_wrapper">
            <div className="main_left col-md-9 col-sm-12">
                    <h1>Cohabitation and Divorce</h1>

                    <p>The meaning of marriage has changed considerably in the past two decades. From being an
                        institution that is supposed to last forever, it has become a relationship that most people
                        dread. For that reason, not many people are comfortable with the idea of marriage anymore and
                        cohabitation is gaining popularity. However, does cohabitation really have anything good to
                        offer? </p>

                    <h3>Why is Cohabitation Becoming Popular These Days?</h3>

                    <p>The fear of a bitter divorce and lack of faith in the “forever and ever” institution has lead
                        many couples to opt for cohabitation arrangements instead of marriages. </p>

                    <p>In fact, many couples who want to get married feel pressured to try living-in together first – in
                        order to test the waters to see if the relationship works or not. Such couples think that the
                        arrangement will work like a ‘trial marriage’ but sadly, it does not. </p>

                    <p></p>

                    <h3>Cohabitation Arrangement – Boon or Hindrance? </h3>

                    <p>Recent studies are enough to dispel any faith people might have in the success of cohabitation
                        arrangements before marriage. According to research, cohabitating couples experience
                        significantly more problems after marriage than couples who do not cohabit before marriage. </p>

                    <p>In addition, cohabitating couples are at a higher risk of facing issues related to alcohol,
                        adultery, independence and drugs than non-cohabitating couples. This study has proved that
                        marriages preceded by a cohabitation arrangement are 50-100% more likely to end up in divorce
                        than marriages between partners who do not cohabit before marriage. </p>

                    <h3>Different Dynamics Lead to a Higher Divorce Rate</h3>

                    <p>Many cohabitating couples like to believe that their relationship is like a marriage without a
                        marriage certificate. However, proponents of cohabitation might find it hard to believe that
                        dynamics of a cohabitation arrangement are very different from that of a marriage. </p>

                    <p>The level of commitment, responsibility and accountability that is required in a marriage cannot
                        be compared to that of a cohabitation arrangement. </p>

                    <p>In addition, cohabitation arrangements lack the one thing that can make all the difference to a
                        marriage - communication. How do you go from an ‘asking no questions’ approach to an ‘open and
                        honest communication’ approach all of a sudden? </p>

                    <p>Cohabitating couples encourage and desire personal independence. Therefore, ‘no questions asked
                        and no answers expected’ approach is followed in the relationship. For that reason, when
                        cohabitating couples get into a marriage, they do not know how to redefine these rules. </p>

                    <p>Such couples are unable to deal with increased amount of expectations and this leads to
                        misunderstandings and resentment. Cohabitating partners have no sense of commitment towards each
                        other because in most cases, each partner is free to walk out of the door whenever he or she
                        pleases – no questions asked. However, that is not how a marriage works. </p>

                    <p>In addition, most of the people who cohabit tend to have commitment issues. When a partner who
                        asked no questions in a cohabitating arrangement suddenly shows an inquisitive side, the
                        commitment-phobic partner is likely to feel suffocated. </p>

                    <p>Therefore, cohabitation arrangements allow the couples to form wrong set of expectations that are
                        hard to correct once the couples get married. </p>

                    <h3>Why Marriage is Better than Cohabitation</h3>

                    <ul>
                        <li>Married people are emotionally and physically better-off than their unmarried, divorced, or
                            cohabitating counterparts. Married people also tend to live longer.
                        </li>

                        <li>It is common for married people to feel more emotionally satisfied with their lives because
                            they feel strong emotional connection with their spouse. In addition, a marriage provides a
                            strong emotional support system that is lacking in a cohabitation arrangement.
                        </li>

                        Married couples know that they can count on each other anytime and during any set of
                        circumstances. However, cohabitating partners do not even know whether the relationship will get
                        past the next day or not – they are always prepared for the worst.

                        <li>Married couples have a more positive approach towards life because of a strong sense of
                            emotional and physical wellbeing. On the other hand, cohabitating couples are more likely to
                            be anxious and negative because they are never sure about their relationship. </li>
                    </ul>

                    <h3>Marriages are Forever</h3>

                    <p>There is a reason why the institution of marriage has been so popular in the past and continues
                        to stay the same even today. The reason is that no other relationship provides a sense of love,
                        fulfilment and comfort as a marriage does. However, it is only when couples can understand what
                        a marriage truly has to offer and what they can offer to a marriage can it work
                        successfully. </p>
            </div>
            <ContentRight/>
        </div>
    );
}

export default Article2;