import React from "react";
import Button from "../../components/Button";
import ContentRight from "../../components/ContentRight";
import MetaTags from "react-meta-tags";

function About() {
    return (
        <div className="content_wrapper">
            <MetaTags>
                <title>About agreements</title>
                <meta id="meta-description" name="description" content="Information on Cohabitation Agreements. Benefits of a Cohabitation and details covered by a Cohabitation Agreement" />
                <meta id="meta-keywords" name="keywords" content="Cohabitation Agreement, DIY Cohabitation Agreements, Quick Cohabitation Agreement, About Agreement, Cohabitation Process, Agreement Online, Solicitor Advice, " />
                <meta id="og-title" property="og:title" content="About Agreements" />
                <meta id="og-image" property="og:image" content="../images/couple.fc414c90.jpg"/>
            </MetaTags>
            <div className="main_left col-md-9 col-sm-12">
                <h1>About Cohabitation Agreements</h1>

                <p>Cohabitation Agreements are chosen by couples who are not married, but would like to regulate
                    clearly their property rights and ensure financial security should their circumstances change.
                    Arrangements for mutual financial support, dealing with debts and looking after children would
                    need to be made in preparation for a change of circumstances occurring. </p>

                <p>Cohabitation Agreements have become popular in Britain today because they <b>clarify financial
                    commitments</b>. Long-term relationships, especially those involving children, involve a
                    partnership as well as a romantic bond. Balancing the budget between income and outgoings,
                    working out who pays what and how responsibilities are shared in running the home all need
                    sorting out to ensure a stress free and harmonious home life.</p>

                <p>Balancing the budget between income and outgoings, working out who pays what and how
                    responsibilities are shared in running the home all need sorting out to ensure a stress free and
                    harmonious home life.</p>

                <p>A Cohabitation Agreement recognises a person’s choice to say ‘I don’t’ to marriage and ‘I do’ to
                    financial responsibility. There is much to be said too for planning ahead, especially to deal
                    with the fallout from unplanned scenarios, such as: another child, separation, long-term illness
                    or death. </p>

                <p>More and more people today are choosing to plan ahead and many are grateful they did.</p>

                <h3>What Your Cohabitation Agreement Covers</h3>

                <ul>
                    <li>A statement containing the <b>purpose of the contract</b></li>
                    <li>Your <b>full names, addresses and ages</b> with a <b>disclosure</b> of your financial
                        position and your current state of health.
                    </li>
                    <li><b>Duration</b> - how long you want the agreement to last for</li>
                    <li><b>Property</b> - how you intend to deal with the property you owned before the relationship
                        and property you acquire during the relationship
                    </li>
                    <li><b>Income/expenses</b> - whether you will pool your income into a joint account, or keep
                        separate accounts, whether either of you will support the other if she or he gives up work
                        and for how long
                    </li>
                    <li><b>Children </b>- whether or not you plan to have any, how you want them to be educated etc
                    </li>
                    <li><b>Inheritance and Wills</b> - what you plan to leave to each other. Bear in mind that
                        marriage will make void any will you already have so you'll need to make a new one after
                        tying the knot
                    </li>
                    <li><b>Changes</b> - what happens if either of you wants to change the contract or if you
                        separate
                    </li>
                    <li><b>Extras</b> - because a cohabitation contract is a relatively informal document, you can
                        customise it to your own requirements - and some couples include it in their plans for
                        holidays (who chooses where to go) and how they will go about sharing maintenance and the
                        housework
                    </li>
                </ul>
                <div className="index_btn1">
                    <Button imageName="order_now3a" imagePath="images/BTN-order-now.gif" imagePathRestore="images/BTN-order-now-over.gif"
                            alt="Order Now" to="ordernow.html" />
                </div>
                <a href="services.html">
                    <div className="index_btn2"></div>
                </a>
            </div>
            <ContentRight />
        </div>
    );
}

export default About;