import React from "react";
import ContentRight from "../../components/ContentRight";
import MetaTags from "react-meta-tags";

function Disclaimer() {
        return (
            <div className="content_wrapper terms">
                <MetaTags>
                    <title>Disclaimer</title>
                    <meta id="meta-description" name="description" content="Use of our Cohabitation Agreement service is subject to the conditions detailed in our Disclaimer" />
                    <meta id="meta-keywords" name="keywords" content="Cohabitation Agreement, DIY Cohabitation, Quick Cohabitation, Terms and Conditions, Agreement Terms, Agreement Conditions, Online Cohabitation Agreement, Cohabitation Advice, Agreement Information, Cheap Agreement " />
                    <meta id="og-title" property="og:title" content="Disclaimer" />
                    <meta id="og-image" property="og:image" content="../images/couple.fc414c90.jpg"/>
                </MetaTags>
                <div className="main_left col-md-9 col-sm-12">
                    <h1>Disclaimer</h1>

                    <p>Use of the Cohabitation Agreement UK online service is subject to the following terms and
                        conditions: </p>

                    <h3>1) Jurisdiction</h3>

                    <p>All documentation and advice contained on the cohabitation-agreement.co.uk website relates to
                        proceedings lodged in Courts in England and Wales and not in any other region of the United
                        Kingdom or outside the United Kingdom.</p>

                    <p>Cohabitation Agreement UK documentation has no jurisdiction or legality in countries such as
                        members of the European Union or the United States or Canada.</p>

                    <p>This agreement is covered by the Laws of England and Wales. Any rights not expressly granted
                        herein are reserved. These terms and conditions shall not affect your statutory rights as a
                        consumer.</p>

                    <h3>2) Limitations</h3>

                    <p>Cohabitation Agreement UK provides clients with documents and advice on the completion of
                        documents required to initiate a cohabitation agreement in England and Wales.</p>

                    <p>Cohabitation Agreement UK does not supply actual or general legal advice in such proceedings
                        and no such legal advice should be implied in the use of this service.</p>

                    <p>Cohabitation Agreement UK does not accept liability or responsibility for any additional
                        advice that clients seek in conjunction with their documentation and/or advice.</p>

                    <p>Cohabitation Agreement UK does not accept any liability in relation to any actual proceedings
                        issued by clients.</p>

                    <p>Cohabitation Agreement UK provides documentation on the understanding that clients are acting
                        in person in relation to proceedings.</p>

                    <p>Documents and advice contained on the cohabitation-agreement.co.uk website are not made
                        available for any other purpose.</p>

                    <h3>3) General</h3>

                    <p>Whilst every care has been taken in the preparation of documents and advice, clients must
                        note that in the majority of cases these need to be tailored to suit their particular
                        circumstances. It is a condition of using the cohabitation-agreement.co.uk website that
                        clients accept that the documentation, software purchased, or the facilities used, are
                        suitable to be used by them in conjunction with proper advice and adaptation for their
                        particular requirements.</p>

                    <p>Cohabitation Agreement UK does not accept responsibility or liability for any losses incurred
                        by its users.</p>

                    <p>If a Court of Law later decides that any part of this disclaimer is not reasonable or cannot
                        be enforced for any reason, that decision will not affect or relate to any other parts of
                        the disclaimer which will be enforced or continue to be enforceable in any event.</p>

                    <h3>Privacy Policy</h3>

                    <p>Cohabitation Agreement UK is committed to protecting the identity and privacy of our users.
                        We will only use information collected in the process of transactions in a strictly lawful
                        manner in accordance with the Data Protection Act 1998.</p>

                    <p>By purchasing from us, your details will be entered into our customer database. We will never
                        sell or otherwise share your name, email address, or other personal information with any
                        other business or marketing campaign without your permission. We will however, send you
                        information from time to time detailing our product updates and special offers. You may
                        opt-out of receiving these notifications at any time after purchasing by using the
                        unsubscribe link provided. All members' personal data will be saved in accordance with UK
                        and EU privacy laws and the Data Protection Act 1998.</p>

                    <p>Users should be aware that Cohabitation Agreement UK retains no information relating to
                        client’s credit card details; this information is only retained by the Merchant Service
                        Provider.</p>

                    <h3>Modifications</h3>

                    <p>Cohabitation Agreement UK reserves the right to change the terms and conditions of business
                        and disclaimers relating to our website service.</p>

                <a href="services.html">
                    <div className="index_btn2"></div>
                </a>
            </div>
            <ContentRight />
        </div>
        );
}

export default Disclaimer;