import React from "react";
import {Link} from "react-router-dom";

function Header(props) {
    return (
        <div id="header">
            <div className="header_inner clearfix container">

                {/* responsive menu moved from Bottom*/}
                <div className="sub-menu-header_icon">
                    <div className="menu-toggle">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>

                <div className="hleft">
                    <a href="/" name="top"><img src="images/logo.gif" alt="separation agreement" border="0"/></a>
                </div>

                {/*Menu Moved from bottom to center*/}
                <div className="sub-menu-header desktop">
                    <ul className="top_menu_items">
                        <li><Link className="nav-link" to="/">Home</Link></li>
                        <li><Link className="nav-link" to="about.html">About agreement</Link></li>
                        <li><Link className="nav-link" to="services.html">Services</Link></li>
                        <li><Link className="nav-link" to="ordernow.html"><b>Order now</b></Link></li>
                        <li><Link className="nav-link" to="testimonials.html">Testimonials</Link></li>
                    </ul>
                    {/*<nav className="navbar navbar-expand-lg navbar-light">
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"                                     aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>

                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav mr-auto">
                                <li className="nav-item">
                                    <Link className="nav-link" to="/">Home</Link>
                                </li>
                                <li className="menu-lim">|</li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="about.html">About agreement</Link>
                                </li>
                                <li className="menu-lim">|</li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="services.html">Services</Link>
                                </li>
                                <li className="menu-lim">|</li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="ordernow.html"><b>Order now</b></Link>
                                </li>
                                <li className="menu-lim">|</li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="testimonials.html">Testimonials</Link>
                                </li>
                                <li className="menu-lim">|</li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="guarantee.html">Guarantee</Link>
                                </li>

                                <li className="menu-lim">|</li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="contact.html">Contact us</Link>
                                </li>
                                <li className="menu-lim">|</li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="why-choose-us.html">Why choose us</Link>
                                </li>
                                <li className="menu-lim">|</li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="faq.html">FAQ</Link>
                                </li>
                            </ul>
                        </div>
                    </nav>*/}
                </div>

                {/*<div className="hright">
                    <img src="images/phone-number.gif" alt="phone number 0800 440 2512" border="0"/>
                </div>*/}

                {/*Header Contact Us section*/}
                <div className="head_contact">
                    <div className="bg_image_contact">
                        <span className="title">call us on</span>
                        <a href="tel:01656 508 262" className="call">01656 508 262</a>
                        <ul className="list-inline">
                            <li><Link className="nav-link" to="why-choose-us.html">Why choose us</Link></li>
                            <li>|</li>
                            <li><Link className="nav-link" to="faq.html">FAQ</Link></li>
                        </ul>
                    </div>         
                </div>
            </div>
            <div id ="submenu_toggle" className="row sub-menu-header">

                        <div className="head_submenu" id="navbarSupportedContent">
                            <ul className="navbar-nav mr-auto">
                            <li className="nav-item">
                                    <Link className="nav-link" to="/">Home</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="about.html">About agreement</Link>
                                </li>
                                
                                <li className="nav-item">
                                    <Link className="nav-link" to="services.html">Services</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="ordernow.html"><b>Order now</b></Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="testimonials.html">Testimonials</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="guarantee.html">Guarantee</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="contact.html">Contact us</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="why-choose-us.html">Why choose us</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="faq.html">FAQ</Link>
                                </li>
                            </ul>
                        </div>
            </div>
        </div>
    )
        ;
}

export default Header;