import React from "react";
import {Link} from "react-router-dom";

function ContentRight() {

    return (
        <div className="content_right col-md-3 col-sm-12">
            <div className="row">
                <div className="col-md-12 col-sm-6">
                    <Link to="support.html" className="support">
                        <img src="images/want-to-know-more.gif" alt="support" border="0"/>
                    </Link>
                </div>
                <div className="col-md-12 col-sm-6">
                    <Link to="personal-service.html" className="option1">
                        <img src="images/personal-service.gif" alt="personal service" border="0"/>
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default ContentRight;