import React from "react";
import Button from "../../components/Button";
import ContentRight from "../../components/ContentRight";
import MetaTags from "react-meta-tags";


function WhyChooseUs() {

    return (
        <div className="content_wrapper">
            <MetaTags>
                <title>Why a pre-nuptial Agreement?</title>
                <meta id="meta-description" name="description" content="We have a team of legally trained experienced professionals who are available to help you complete a Pre-Nuptial agreement" />
                <meta id="meta-keywords" name="keywords" content="Prenuptial Agreement, DIY Agreement, Quick Prenuptial, Sitemap, Online Prenuptial Agreement, Agreement Advice, Cheap Prenuptial Agreement, prenuptial, why us, choose" />
                <meta id="og-title" property="og:title" content="Why a pre-nuptial Agreement?" />
            </MetaTags>
            <div className="main_left col-md-9 col-sm-12">
                <h1>Why choose us?</h1>

                <p><b>Cohabitation Agreement</b> was set up by a group of Legal Experts committed to making the
                    processing of downloadable Cohabitation agreements as quick, simple and affordable as possible.
                </p>

                <p>Cohabitation Agreement has been trading since and is firmly established as a highly respected
                    provider of downloadable Cohabitation agreements.</p>

                <p>We are dedicated to providing a quality online service. Every process and document has been
                    double checked by our fully qualified legal experts. So all of our clients can be confident that
                    any advice or documentation we dispense is totally accurate and legally correct.</p>

                <p>We provide all our customers with free phone and e-mail support for any queries they have
                    regarding any aspect of their cohabitation agreement.</p>

                <h3>Why use us?</h3>

                <ul>
                    <li><b>FREE Support</b> – help and advice on any related subject via email or over the phone
                    </li>
                    <li><b>FREE Updates &amp; Upgrades</b> - notification of law changes or requirements</li>
                    <li><b>FREE Guide</b> - The Essential Guide to Cohabitation Agreements (worth £19.99)</li>
                    <li><b>Discounted Will</b> (Just £10) - show loved ones they will be provided for</li>
                    <li><b>GUARANTEE</b> - no-quibble, 100% money back guarantee</li>
                    <li><b>Your Cohabitation Agreement Completed For You</b> - fast and stress-free</li>
                    <li><b>Solicitor Drafted Agreement</b> - no loopholes, for a fraction of the High St price</li>
                    <li><b>FREE Consultation</b> – discuss any queries you have with a legal profession</li>
                </ul>

                <p>We believe that we have developed the quickest, simplest, best quality Cohabitation Agreement
                    solutions available in the UK. If within 30 days of purchase you find another UK online solution
                    that offers the same quality of service, documentation and support as us, for less money, we
                    will give you a <b>no-quibble 100% refund GUARANTEED</b>.</p>
                <div className="index_btn1">
                    <Button imageName="order_now3a" imagePath="images/BTN-order-now.gif"
                            imagePathRestore="images/BTN-order-now-over.gif"
                            alt="Order Now" to="ordernow.html"/>
                </div>
                <a href="services.html">
                    <div className="index_btn2"></div>
                </a>
            </div>
            <ContentRight/>
        </div>
    );
}

export default WhyChooseUs;