import React from "react";
import Button from "../../components/Button";
import ContentRight from "../../components/ContentRight";
import {Link} from "react-router-dom";
import MetaTags from "react-meta-tags";


function Sitemap() {

    return (
        <div className="content_wrapper">
            <MetaTags>
                <title>Sitemap</title>
                <meta id="meta-description" name="description" content="Sitemap contains a breakdown of the content in all main pages of the Cohabitation Agreement UK website." />
                <meta id="meta-keywords" name="keywords" content="Cohabitation Agreement, DIY Agreement, Quick Cohabitation, Sitemap, Online Cohabitation Agreement, Agreement Advice, Cheap Cohabitation Agreement " />
                <meta id="og-title" property="og:title" content="Sitemap" />
            </MetaTags>
            <div className="main_left col-md-9 col-sm-12">
                    <h1>Sitemap</h1>

                    <br />

                <h3><a href="about.html">About Cohabitation Agreements</a></h3>
                <p>Cohabitation Agreements, the reasons, requirements and what is included in your
                    agreement.</p>

                <h3><a href="archive.html">Articles Archive</a></h3>
                <p>Archive contains a list of articles about cohabitation agreements</p>

                <h3><a href="credentials.php?form=login">Client Login</a></h3>
                <p>Login to your account</p>

                <h3><a href="contact.html">Contact us </a></h3>
                <p>Call our FREE phone number for answers to any queries. You can also contact us via email, fax
                    or write to our office address. </p>

                <h3><a href="disclaimer.html">Disclaimer</a></h3>
                <p>The terms and conditions agreed to when proceeding to use our service and documentation.</p>

                <h3><a href="wills.html">Discounted Will</a></h3>
                <p>Purchase a Cohabitation Agreement NOW and you'll receive your completed Last Will and
                    Testament at an EXCLUSIVE Special Offer price of £10 (usually £39)</p>

                <h3><a href="faq.html">FAQ</a></h3>
                <p>Find the answers to questions most commonly asked by our past customers.</p>

                <h3><a href="free-ebook.html">FREE eBook</a></h3>
                <p>The Essential Guide to Cohabitation Agreements</p>

                <h3><a href="guarantee.html">Guarantee</a></h3>
                <p>All of our products are covered by our 100% refund guarantee.</p>

                <h3><a href="/">Home</a></h3>
                <p>We believe that we have produced the UK’s most efficient, value for money Cohabitation
                    agreement services available online.</p>

                <h3><a href="ordernow.html">Order Now</a></h3>
                <p>Order online now to initiate your cohabitation agreement and be applicable to receive a new
                    Will our reduced price of £10 (usually £39).</p>

                <h3><a href="personal-service.html">Personalised service</a></h3>
                <p>Pay £67 for our great personalised service and have your Cohabitation Agreement drafted
                    uniquely for you by one of our experienced members of staff.</p>

                <h3><a href="privacy-policy.html">Privacy Policy</a></h3>
                <p>We take our customers privacy very seriously and take every step to ensure that their privacy
                    is protected.</p>

                <h3><a href="services.html">Services</a></h3>
                <p>Choose from our two great service options to have a Cohabitation Agreement that suits your
                    needs.</p>

                <h3><a href="solicitor-service.html">Solicitor Service</a></h3>
                <p>Pay just a fraction of the High Street price for a Cohabitation Agreement drafted for you by
                    a qualified solicitor after a consultation to answer any of your queries. Comprehensive
                    service for just £97. </p>

                <h3><a href="support.html">Support</a></h3>
                <p>Contact us via phone, email, post or fax for help advice on any aspect of using our
                    service.</p>

                <h3><a href="terms.html">Terms and Conditions</a></h3>
                <p>These terms and conditions must be adhered to prior to use of our service.</p>

                <h3><a href="testimonials.html">Testimonials</a></h3>
                <p>Find out what our satisfied customers have said about us.</p>

                <h3><a href="why-choose-us.html">Why Choose Us</a></h3>
                <p>Our comprehensive service, years of experience and affordable prices makes us the first
                    choice for solicitor drafted Cohabitation Agreement services.</p>
                <div className="index_btn1">
                    <Button imageName="order_now3a" imagePath="images/BTN-order-now.gif"
                            imagePathRestore="images/BTN-order-now-over.gif"
                            alt="Order Now" to="ordernow.html"/>
                </div>
                <Link to="services.html">
                    <div className="index_btn2"></div>
                </Link>
            </div>
            <ContentRight/>
        </div>
    );
}

export default Sitemap;