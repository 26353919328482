import React from "react";
import Button from "../../components/Button";
import ContentRight from "../../components/ContentRight";
import MetaTags from 'react-meta-tags';
import {Link} from "react-router-dom";


function Home() {

    return (
        <div className="content_wrapper">
            <MetaTags>
                <title>Cohabitation agreement - Home</title>
                <meta id="meta-description" name="description"
                      content="Quick, simple and affordable cohabitation Agreements. Order online and complete questionnaire to have agreement drafted for you by a qualified Solicitor."/>
                <meta id="meta-keywords" name="keywords"
                      content="Cohabitation Agreement, About Cohabitation Agreement, Solicitor Cohabitation Agreement, Quick Cohabitation Agreement, Online Agreement, Cohabitation Advice, Cohabitation Agreement Information"/>
                <meta id="og-title" property="og:title" content="Cohabitation agreement - Home"/>
                <meta id="og-image" property="og:image" content="../images/couple.fc414c90.jpg"/>
            </MetaTags>
            <div className="main_left col-md-9 col-sm-12">
                    <h1>Prefer a quick, simple and affordable cohabitation agreement? </h1>

                    <p>A Cohabitation Agreement clarifies your financial responsibilities and gives you and your partner
                        peace of mind, security and more time to concentrate on enjoying your relationship. Both of our
                        specialist services provide you with:</p>

                    <ul>
                        <li>The <b>most impressive value-for-money</b> service in the UK</li>
                        <li>An <b>immediate solution</b>, initiated in under 2 minutes</li>
                        <li>A <b>quick, efficient and professional</b> legal service</li>
                        <li><b>FREE support</b> throughout the whole process</li>
                        <li><b>Legally-binding assurances</b> for both parties</li>
                        <li><b>50 years experience</b> of family law</li>
                        <li>No quibble, 100% refundable <b>Guarantee</b></li>
                    </ul>

                    <p>With our complete Solicitor Managed Service, you can have your <b>simple-to-follow;</b> <b>professionally-completed
                        and concisely-written</b> Cohabitation Agreement downloaded straight to your desktop. There are
                        no tedious or confusing legal forms, just answer a few simple questions and we will draft your
                        agreement. </p>

                    <p>Four years ago we had the initiative to make our service available online. Since then we have
                        drafted Cohabitation Agreements to thousands of satisfied customers. No other online service can
                        provide such a high quality in such efficient timing and for such a reasonable price.</p>

                    <p>Don't get caught up in damaging domestic disputes that may cause your relationship to suffer.
                        Move into your new happier life with your partner, without any stress or expense.</p>
                <div className="index_btn1">
                    <Button imageName="order_now3a" imagePath="images/BTN-order-now.gif"
                            imagePathRestore="images/BTN-order-now-over.gif"
                            alt="Order Now" to="ordernow.html"/>
                </div>
                <a href="services.html">
                    <div className="index_btn2"></div>
                </a>
            </div>
            <ContentRight/>
        </div>
    );
}

export default Home;