import React from "react";
import ContentRight from "../../components/ContentRight";


function Article10() {
    return (
        <div className="content_wrapper">
            <div className="main_left col-md-9 col-sm-12">
                <h1>When to Introduce Your New Partner to Your Kids</h1>

                <p>For children, there is only one world, the world of family. Family means everything to them, it
                    is the place where they learn their norms, values and the way society works, it is their comfort
                    zone. When a child realises their family is being split up, their whole world becomes disrupted
                    and it is at this crucial time they need to be reassured and their worries and concerns
                    addressed. </p>

                <p>A child does not understand the subtle nuances of adult relationships. Therefore, it is common
                    for children to feel that one of their parents is deserting them after divorce. During this
                    rough period, if a child is introduced to a new partner by either of the parents, it is most
                    likely the child will not like the new partner. </p>

                <p>In addition, the child may start displaying other emotional problems, which might be hard to deal
                    with. For that reason, it is extremely important for parents to understand when they should
                    introduce their new partner to their child. </p>

                <p>It is important to look at the situation from your child’s perspective in order to make sure that
                    you do the right thing at the right time. </p>

                <h3>Why Children Have a Hard Time Accepting a New Partner</h3>

                <ul>
                    <li><b>Difficult to Accept Changed Circumstances</b>
                        <p>While it may be easy for you to accept the changed circumstances because you may have
                            experienced a rough marriage, no reason will ever seem like a valid reason to a child.
                            For that reason, it is very tough for a child to accept divorce. </p>

                        <p>Experts agree that children often take months or even years to feel normal about the
                            divorce. Usually, younger children take a longer time because of their emotional
                            vulnerability. </p>

                        <p>When parents introduce their new partner to their child during this adjustment phase, a
                            child is mentally and physically not capable of handling any more change in his or her
                            life. For that reason, a child may not know how to deal with another changed set of
                            circumstances and may not like the idea of a new partner. </p>
                    </li>
                    <li><b>Secret Wish to See the Parents Reunite</b>
                        <p>Children often secretly wish for their parents to reunite even after divorce. Eventually
                            children do understand this is not going to happen but building an understanding of this
                            does not happen overnight.</p>

                        <p>For that reason, it is important for a child to get out of this phase and understand the
                            truth, however, you cannot impose this truth upon your child. While you can tell your
                            child that divorce is an adult decision and that you do not intend to get back with the
                            other parent, a child needs time to absorb this fact. </p>

                        <p>If a child is introduced to a new partner when the child is secretly hoping for the
                            parents to get back together, the child is likely to react negatively towards the new
                            partner. They will see them as a threat, getting in the way of their parents
                            reuniting. </p>
                    </li>
                </ul>

                <h3>Things to Keep in Mind When Trying to Introduce Your Kids to Your New Partner</h3>

                <ul>
                    <li>Wait to disclose a new relationship to your child unless you are completely certain it will
                        last. Often, adults think that the relationship is serious, only to realise the flaws of the
                        new relationship at a later point in time.
                    </li>

                    <li>Do not introduce the subject of your new partner unless you are sure that your child has
                        started leading a normal life again. If your child has not adjusted to your divorce, there
                        is no point creating further turmoil in his or her life.
                    </li>

                    <li>Try not to cohabit with your new partner for at least a year after divorce so that your
                        child does not feel overwhelmed by the number of changes in his or her life.
                    </li>

                    <li>Once you feel that your child is ready to meet a new partner, introduce them very gradually
                        and do not push your child into doing something they are not comfortable with.
                    </li>
                </ul>
            </div>
            <ContentRight/>
        </div>
);
}

export default Article10;