import React from "react";
//import {Link} from "react-router-dom";
import ContentRight from "../../components/ContentRight";
import MetaTags from "react-meta-tags";


function Archive() {

    return (
        <div className="content_wrapper">
            <MetaTags>
                <title>Articles Archive</title>
                <meta id="meta-description" name="description" content="Archive contains a list of articles about pre-nuptial agreements" />
                <meta id="meta-keywords" name="keywords" content="Prenuptial Agreement, DIY Agreement, Quick Prenuptial, Sitemap, Online Prenuptial Agreement, Agreement Advice, Cheap Prenuptial Agreement, prenuptial, articles, archive" />
                <meta id="og-title" property="og:title" content="Articles Archive" />
                <meta id="og-image" property="og:image" content="../images/couple.fc414c90.jpg"/>
            </MetaTags>
            <div className="main_left col-md-9 col-sm-12">
                <h1>Articles Archive</h1>
                <ul>
                    <li><a href="article.html">Advantages of Marriage</a></li>
                    <li><a href="article2.html">Cohabitation and Divorce</a></li>
                    <li><a href="article3.html">Cohabiting Across the World</a></li>
                    <li><a href="article4.html">Cohabiting and Same Sex Couples</a></li>
                    <li><a href="article5.html">Cohabiting – An overview</a></li>
                    <li><a href="article6.html">Settlement in Divorce</a></li>
                    <li><a href="article7.html">Get it Right the Second Time After Divorce</a></li>
                    <li><a href="article8.html">Pros and Cons of Cohabitation</a></li>
                    <li><a href="article9.html">Remarrying After a Divorce</a></li>
                    <li><a href="article10.html">When to Introduce Your New Partner to Your Kids</a></li>
                </ul>
            </div>
            <ContentRight />
        </div>
    );
}

export default Archive;