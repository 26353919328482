import React from "react";
import ContentRight from "../../components/ContentRight";


function Article() {
    return (
        <div className="content_wrapper">
            <div className="main_left col-md-9 col-sm-12">
                <h1>Advantages of Marriage</h1>

                <p>In a time and age when divorce rules the day, marriage as an institution is fast losing its
                    importance. While seeking divorce is very easy, making a marriage work is a very tough task. In
                    addition, it is not a task that can be accomplished overnight. It takes years of commitment,
                    patience, perseverance and mutual respect to make a marriage last a lifetime. </p>

                <p>Many people think that most marriages cannot stand the test of time. However, nothing could be
                    further from the truth. Marriages can survive the test of time if both the partners are willing
                    to make the marriage work. Once this commitment is firm, advantages of marriage are immense. In
                    fact, recent studies have proven that marriage is beneficial to men and women in many ways. </p>

                <h3>Benefits of Getting Married</h3>

                <ul>
                    <li><b>Stability</b>
                        <p>Many people think that cohabitation arrangements are like marriage except for the fact
                            that the couple do not receive a marriage certificate. However, the kind of stability
                            marriage offers cannot be matched by any other type of relationship. </p>

                        <p>People involved in live-in relationships never feel stable because they know that the
                            relationship can be ended at any point in time by either of the partners. However, when
                            people enter into a marriage, they know that they are committed towards making their
                            relationship work. Therefore, not only do they feel more stable but they also feel more
                            secure about their relationship.</p>

                        <p>Couples in a marriage know they cannot just walk out of the door. For that reason,
                            couples in a marriage work harder to make their relationship work. </p>
                    </li>
                    <li><b>Marriage is Good for Health </b>
                        <p>Recent studies prove that marriage can improve people’s health. In fact, according to
                            research, men tend to reap more health benefits than women, while women reap more
                            financial benefits. </p>

                        <p>Married people tend to have better health habits, stronger social support networks and
                            lower mortality rates than their unmarried or divorced counterparts. All these factors
                            help to boost the overall physical and mental health of people. The emotional support
                            experienced in a marriage leads to a higher level of emotional wellbeing. </p>

                        <p>Research proves that married people experience less psychological disorders, anxiety, and
                            depression. On the other hand, divorced and unmarried couples are more likely to be
                            psychologically disturbed and depressed. </p>
                    </li>
                    <li><b>Financial Benefits</b>
                        <p>Married couples are more concerned about managing their household and their finances than
                            unmarried couples. For that reason, married couples tend to save and plan for their
                            future in a much better manner than unmarried or divorced couples. </p>

                        <p>According to recent studies, married couples tend to take up more stable jobs than
                            unmarried people. Therefore, married people are more likely to make rational decisions
                            about their career and lifestyle choices. </p>

                        <p>Married couples have higher chances of developing their asset base in a planned manner
                            rather than spending all their money. Therefore, married couples are less likely to face
                            a financial crisis. </p>
                    </li>
                    <li><b>Marriage Offers Stability to Children</b>
                        <p>These days, it is common for cohabitating couples to have children without getting
                            married. However, this can have negative consequences for the children because children
                            might end up feeling confused about adult relationships. </p>

                        <p>Children get a more stable environment when their parents are married. A two-parent
                            household also provides a better standard of living for the children. Subsequently,
                            children end up feeling more secure financially as well as emotionally. </p>
                    </li>
                    <li><b>Better Physical Intimacy</b>
                        <p>While many people in live-in relationships feel that being with multiple partners is more
                            exciting, the truth is that being with a single partner is more fulfilling as well as
                            emotionally rewarding. For married couples, physical intimacy is not only about sex but
                            also about an emotional connection, which is far more satisfying. </p>
                    </li>
                </ul>
            </div>
            <ContentRight/>
        </div>
    );
}

export default Article;