import React from "react";
import ContentRight from "../../components/ContentRight";


function Article11() {
    return (
        <div className="content_wrapper">
            <div className="main_left col-md-9 col-sm-12">
                <h1>Wedding Customs &amp; Superstitions</h1>

                <p>Since the dawn of time, we have believed in the powers of the unknown and the possibility of good
                    and bad luck. As people we like to feel in control of our destiny. If we do something good for
                    someone, we hope that karma will do us a good turn. If we don't drop that mirror we believe we
                    will be saved from seven years bad luck. We don't walk under ladders or put shoes on the table.
                    We salute magpies and we feel reassured when a black cat crosses our path. All these
                    superstitions are psychological, they have no place in logic; only the power of belief, which is
                    why it is no surprise to find that our wedding ceremonies are salted with many good luck charms
                    which derive from our ancestors.</p>

                <h3>Wedding Ceremony</h3>

                <p>In today's wedding ceremony, the bride is not allowed to see the groom before the wedding. If the
                    groom sees the wedding dress, the marriage will be plagues with bad luck.</p>

                <p>The bride has to wear, <i>something old, something new, something borrowed and something
                    blue</i> to follow tradition and equip her with all the luck she will need.</p>

                <p><b>'Something old'</b> referred to the couples friends who will hopefully remain close during the
                    marriage. Traditionally this was old garter, which was given to the bride by a happily married
                    woman in the hope that the happiness would be passed on to the new bride.</p>

                <p><b>'Something new'</b> symbolised the newly weds prosperous future.</p>

                <p><b>'Something borrowed'</b> was often lent by the bride's family and is an item much valued by
                    the family. The bride must return the item to ensure good luck.</p>

                <p><b>'Something blue'</b> represented a bride's fidelity and originated in ancient Israel where a
                    bride would wear a blue ribbon in her hair.</p>

                <h3>Proposals</h3>

                <p>Back in the old days when marriage proposals were more formal, the prospective groom sent his
                    friends or family to check with his prospective bride whether she would like to marry him.
                    However, if on this journey, they saw or passed a monk, a pregnant woman or a blind man they had
                    to turn around for fear the marriage would be doomed by bad omens. However, the luck would be
                    significantly better if they saw a nanny goat, pigeons or wolves and would bring good fortune to
                    the marriage.</p>

                <p>During Medieval times in Brittany, a man would propose by leaving a hawthorn branch at the door
                    of the girl he wanted to marry on the first of May. If the girl left the branch at the door, it
                    signified her acceptance of the proposal. However, if she replaced the hawthorn branch with a
                    cauliflower, this signified her refusal to marry.</p>

                <h3>Surnames</h3>

                <p>If a woman married a man whose surname began with the same letter as hers, it was considered
                    unlucky. The following rhyme explains the belief:</p>

                <p className="ml-3"><i>To change the name and not the letter <br />
                    Is to change for the worst and not the better.</i></p>

                <p>If the bride practised writing her new name before she got married, she would also receive bad
                    luck.</p>

                <h3>Days</h3>

                <p>If you got married on Saturday, like most weddings are today, it was considered unlucky,
                    different days of the week were believed to be either lucky or unlucky to get married on as the
                    following rhyme describes.</p>

                <p className="ml-3">
                    <i>Monday for wealth<br />
                        Tuesday for health<br />
                        Wednesday the best day of all<br />
                        Thursday for losses<br />
                        Friday for crosses<br />
                        Saturday for no luck at all</i></p>

                <h3>Months</h3>

                <p>The month in which you married was also scrutinized and considered superstitiously as the
                    following rhyme addresses.</p>

                <p className="ml-3">Married when the year is new, he'll be loving, kind and true.<br />
                    When February birds do mate, You wed nor dread your fate.<br />
                    If you wed when March winds blow, joy and sorrow both you'll know.<br />
                    Marry in April when you can, Joy for Maiden and for Man.<br />
                    Marry in the month of May, and you'll surely rue the day.<br />
                    Marry when June roses grow, over land and sea you'll go.<br />
                    Those who in July do wed must labour for their daily bred.<br />
                    Whoever wed in August be, many a change is sure to see.<br />
                    Marry in September's shrine, your living will be rich and fine.<br />
                    If in October you do marry, love will come but riches tarry.<br />
                    If you wed in bleak November, only joys will come, remember.<br />
                    When December snows fall fast, marry and true love will last.</p>

                <p>The month of May is considered an extremely unlucky time to marry for many different reasons.</p>

                <ul>
                    <li>In Pagan times, the start of summer was when the festival of Beltane was celebrated with
                        outdoor orgies. This was therefore thought to be an unsuitable time to begin married life
                    </li>
                    <li>In Roman times, the Feast of the Dead and the Festival of the Goddess of Chastity both
                        occurred in May.
                    </li>
                    <li>In Victorian times, advice was taken more seriously than it is today and in most churches,
                        the end of April was a busy time for weddings as couples wanted to avoid getting married in
                        May.
                    </li>
                    <li>Queen Victoria is believed to have forbidden her children from marrying in May.</li>
                </ul>

                <p>If a couple married during Lent, a period of abstinence, it was considered extremely
                    inappropriate, as the saying goes 'marry in Lent, you'll live to repent.'</p>

                <p>June was named after Juno, the Roman Goddess of love and marriage and so was obviously believed
                    to be an appropriate time to marry.</p>

                <p>Apart from May, the summer was considered a wonderful time to marry and this is partly due to the
                    sun's association with fertility.</p>

                <p>In Scotland, one popular custom was for the bride to “walk with the sun” to bring her goodness.
                    To do this, she would walk from east to west on the south side of the church and then continue
                    walking around the church three times.</p>

                <h3>Wealth</h3>

                <p>To ensure wealth in the couples married life, the bride would place a silver sixpence in her
                    shoe. Today, some brides substitute the silver sixpence with a penny.</p>

                <p><b>It is thought unlucky for the bride to:</b></p>

                <ul>
                    <li>Make her own wedding dress</li>
                    <li>Wear her entire outfit before the wedding day.</li>
                </ul>

                <p>Some brides leave a final stitch on the dress undone until it is time to leave for the ceremony
                    when the outfit is completed.</p>

                <h3>Dress</h3>

                <p>The most obvious and common colour for a wedding dress is white, because it symbolises
                    maidenhood, a tradition which started with the rich in the sixteenth century. This tradition was
                    further promoted by Queen Victoria choosing to marry in white instead of silver, which had long
                    been the traditional colour of Royal brides. Before the white dress in the sixteenth century,
                    brides used to wear their best dresses and they chose the colour they preferred without any
                    symbolic connotation attached to it. The following rhyme reflects opinion on wedding dress
                    colour at the time:</p>

                <p className="ml-3"><i>Married in White, you have chosen right,<br />
                    Married in Blue, your love will always be true,<br />
                    Married in Pearl, you will live in a whirl,<br />
                    Married in Brown, you will live in town,<br />
                    Married in Red, you will wish yourself dead,<br />
                    Married in Yellow, ashamed of your fellow,<br />
                    Married in Green, ashamed to be seen,<br />
                    Married in Pink, your spirit will sink,<br />
                    Married in Grey, you will go far away,<br />
                    Married in Black, you will wish yourself back.</i></p>

                <p>A green dress was thought to be unlucky unless the bride was Irish. If a woman had a green gown
                    it implied promiscuity, the green staining being due to rolling in grassy fields.</p>

                <h3>Veil</h3>

                <ul>
                    <li>Back in days of old, brides were thought to be particularly vulnerable to evil spirits and
                        many of the customs and traditions associated with weddings were to provide protection. In
                        relation to the veil, it was originally worn by Roman brides, who it was believed it would
                        disguise and therefore outwit malevolent spirits.
                    </li>
                    <li>It wasn't until the 19th century that the veil increased in popularity, as it was associated
                        with modesty and chastity.
                    </li>
                    <li>In some Eastern ceremonies the bride is veiled and the groom is not allowed to see the
                        bride's face until after the wedding ceremony.
                    </li>
                    <li>In some Jewish weddings there is a ritual where the groom ensures that the bride is his
                        intended before placing the veil over her face.
                    </li>
                </ul>

                <h3>Flowers</h3>

                <ul>
                    <li>Since history began, flowers have been a universal addition to a wedding ceremony. Flowers
                        are chosen on the basis of their symbolic meanings. Orange blossom for example has always
                        been associated with weddings because it signifies purity and chastity.
                    </li>
                    <li>Peonies represent shame, according to belief and as a result are avoided. Azaleas represent
                        temperance, roses symbolize love and snowdrops represent hope.
                    </li>
                    <li>Red and white flowers together are avoided because they represent blood and bandages</li>
                    <li>In some cultures, lilies symbolize majesty but are thought unlucky by others because of
                        their association with death.
                    </li>
                    <li>In times when a Knight would wear his lady's colours to display his love he would choose a
                        flower for his buttonhole that was also in the bride's bouquet.
                    </li>
                    <li>When the bride throws her bouquet, it is believed that whoever catches it will be the next
                        one to marry.
                    </li>
                </ul>

                <h3>Journey</h3>

                <ul>
                    <li>When the bride is ready to leave the house for the wedding ceremony a last look in the
                        mirror will bring her good luck. However returning to the mirror once she has begun her
                        journey will result in bad luck.
                    </li>
                    <li>Seeing a chimney sweep on the way to a wedding is thought to bring good luck and it is still
                        possible to hire one to attend wedding ceremonies. Other good luck omens when seen on the
                        way to the ceremony include lambs, toads, spiders, black cats and rainbows.
                    </li>
                    <li>Seeing an open grave, a pig, a lizard, or hearing a cockerel crow after dawn are all thought
                        to be omens of bad luck. Monks and nuns are also a bad omen. This may be because they are
                        associated with poverty and chastity. They are also thought to signal a dependence on
                        charity by the newlyweds.
                    </li>
                    <li>Bad weather on the way to the wedding is thought to be an omen of an unhappy marriage,
                        although in some cultures rain is considered a good omen. Cloudy skies and wind are believed
                        to cause stormy marriages. Snow on the other hand is associated with fertility and wealth.
                    </li>
                </ul>

                <h3>Bridesmaids</h3>

                <ul>
                    <li>Bridesmaids were dressed similarly to the bride in order to confuse evil spirits and protect
                        the bride.
                    </li>
                    <li>After some weddings the bride will immediately buy a pin off her bridesmaid. This is because
                        it is believed that whichever partner buys the first item after a marriage will be the most
                        dominant one in the relationship.
                    </li>
                </ul>

                <h3>Wedding Cake</h3>

                <ul>
                    <li>Cutting the wedding cake is now part of the ritual celebrations at the reception. The couple
                        make the first cut together to symbolize their shared future.
                    </li>
                    <li>Cakes have been associated with weddings throughout history. The Romans shared a cake during
                        the wedding ceremony itself. This was not the rich fruit-cake we enjoy today. It was a plain
                        confection made from wheat flour, salt and water. The Fijians and Some Native American
                        tribes still incorporate cake in their wedding ceremonies.
                    </li>
                    <li>In Britain early cakes were flat and round and contained fruit and nuts which symbolize
                        fertility.
                    </li>
                    <li>In the past the custom was to throw many small cakes over the bride in a similar way in
                        which we throw confetti today. A modification of this custom was to crumble cake over the
                        brides head and in some versions to break the cake over the Bride's head. In Scotland Oat
                        Cakes were used for this purpose. This was done to promote fertility.
                    </li>
                    <li>In Yorkshire a plate holding wedding cake was thrown out of the window as the bride returned
                        to her parental home after the wedding. If the plate broke she would enjoy a happy future
                        with her husband but if the plate remained intact her future would be grim.
                    </li>
                    <li>Another old English custom was to place a ring in the wedding cake. The guest who found the
                        ring in their piece of cake would be ensured happiness for the next year.
                    </li>
                    <li>The shape of the modern three tiered iced cake is believed to have been inspired by the
                        spire of Saint Bride's Church in the City of London.
                    </li>
                    <li>It is said that unmarried guests who place a piece of wedding cake under their pillow before
                        sleeping will increase their prospects of finding a partner and bridesmaids who do likewise
                        will dream of their future husbands.
                    </li>
                    <li>The top tier of the cake is often kept by couples for the christening of their first
                        child.
                    </li>
                </ul>

                <h3>Confetti</h3>

                <p>The word 'confetti' is Italian for 'sweets' which in Italy are thrown over the couple as they
                    emerge from the church, in the same way that we use paper confetti today. In some weddings today
                    raisins and nuts are used.</p>

                <p>Before we used the paper version of confetti, a couple would have been showered with flowers,
                    petals, rice or grains which was thought to bestow prosperity and fertility on the couple.</p>

                <h3>Shoes</h3>

                <ul>
                    <li>Shoes tied to the back of a car are thought to bring good luck, this evolved from the Tudor
                        custom where guests would throw shoes at the newlywed couple. It was considered lucky if
                        they or their carriage were hit by the shoes.
                    </li>
                    <li>Less well known is for the bride's father to give the groom a pair of the bride's shoes to
                        symbolise the passing of responsibility for the daughter to her new husband. A variation of
                        the custom is for the groom to tap the bride on the forehead with one of the shoes to assert
                        his dominance.
                    </li>
                    <li>The custom of the bride throwing her bouquet over her shoulder was originally performed by
                        her throwing one of her shoes over her shoulder.
                    </li>
                </ul>

                <h3>Threshold</h3>

                <p>It is believed that once the couple are married the husband must carry his new wife through the
                    main entrance of their home. There is a lot of uncertainty as to the source of this myth,
                    however one explanation is that if the bride falls when entering her new home it will bring bad
                    luck. The second explanation for this is that the bride would be unlucky if she stepped into the
                    marital home with her left foot first. The third explanation is the idea that by carrying the
                    bride over the threshold follows the Anglo-Saxon tradition of the groom stealing his bride and
                    carrying her off.</p>

                <h3>The best man</h3>

                <ul>
                    <li>It is the best man's duty to protect the groom from bad luck. It is the best man's duty to
                        ensure that once the groom has started his journey to the church, he does not return for any
                        reason.
                    </li>
                    <li>The best man must also arrange for the groom to carry a small mascot or charm in his pocket
                        on the wedding day for good luck.
                    </li>
                    <li>When the best man is paying the church minister's fee he should pay him an odd sum to bring
                        luck to the couple.
                    </li>
                </ul>

                <h3>Honeymoon</h3>

                <p>The term 'Honeymoon' originates from the times when a man captured his bride and hid from the
                    bride's parents before marrying. The couple would remain in hiding for a further cycle of the
                    moon after the wedding. During this period they would drink honey wine, hence where the name
                    itself originates.</p>

                <p>In Scotland the custom was for a woman with milk in her breasts to prepare the marital bed to
                    encourage fertility in the newlyweds. </p>

                <p>In Ireland a laying hen was tied to the bed on the first honeymoon night in the hope that some of
                    its fertility would be passed on to the couple. Eating a double yoked egg was also thought to
                    bring fertility. </p>
            </div>
            <ContentRight/>
        </div>
    );
}

export default Article11;