import React from "react";
import $ from 'jquery';
import 'bootstrap';
import MetaTags from "react-meta-tags";


class Contact extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            arrValidDates: ['YY/DD/MM', 'YYMMDD', 'YYYYMMDD', 'YYYY-MM-DD'],
            iFormatDate: 0,
            sErrIsEmpty: " Field required.\n",
            sErrFormat: "Err!",
            sErrNotChoosen: " is not choosen.\n",
            sErrValidateTextMinLength1: "It must be at least ",
            sErrValidateTextMinLength2: " characters.\n",
            sErrValidateEmail: "It is not a valid e-mail address.\n",
            sErrName : "",
            sErrEmail: "",
            sErrLastName: "",
            sErrPhone: "",
            sErrBoxComment: "",
            sErrorMsg:   "",
            sGeneralMsg: "",
        }
    }

    checkErrorMsgStatus(){
        return this.state.sErrName + this.state.sErrEmail + this.state.sErrLastName + this.state.sErrPhone + this.state.sErrBoxComment;
    }

    validateText(sText, sName, iMinLength, iMaxLength)
    {
        let sErrorTextMsg = "";
        sText = $.trim(sText);

        if(sText === "") // If empty
        {
            sErrorTextMsg = this.state.sErrIsEmpty;
        }
        else if(sText !== "") // else if to short or to long string
        {
            if(iMinLength !== null && sText.length < iMinLength) {
                sErrorTextMsg += this.state.sErrValidateTextMinLength1 + iMinLength + this.state.sErrValidateTextMinLength2;
            }
            if(iMaxLength !== null && sText.length > iMaxLength)
                sErrorTextMsg += this.state.sErrValidateTextMaxLength1 + iMaxLength + this.state.sErrValidateTextMaxLength2;
        }
        return sErrorTextMsg;
    }


    modalReport(){
        let messageReport = '<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">' +
            '<div class="modal-dialog" role="document">' +
            '<div class="modal-content">' +
            '<div class="modal-header">' +
            '<h5 class="modal-title" id="exampleModalLabel">Please, check the following:</h5>' +
            '<button type="button" class="close" data-dismiss="modal" aria-label="Close">' +
            '<span aria-hidden="true">&times;</span>' +
            '</button>' +
            '</div>' +
            '<div class="modal-body">' + this.state.sGeneralMsg  + '</div>' +
            '<div class="modal-footer">' +
            '<button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>' +
            '</div>' +
            '</div>' +
            '</div>' +
            '</div>';
        $("body", window.parent.document).append(messageReport);
        $('#exampleModal').modal('show');
    }

    validateEmail(sEmailToCheck, sName)
    {
        /* emailChecker = verify a valid email */
        let sErrorEmailMsg = "";
        let emailChecker   = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i; // eslint-disable-line
        let sEmail = $.trim(sEmailToCheck);

        if(sEmail === "")
        {
            sErrorEmailMsg = this.state.sErrIsEmpty;
        }
        else if(sEmail !== "")
        {
            if((!emailChecker.test(sEmailToCheck)))
            {
                sErrorEmailMsg = this.state.sErrValidateEmail;
            }
        }
        return sErrorEmailMsg;
    }

    // validate contact form before sending
    validate(e)
    {
        e.preventDefault();
        $(".modal").remove();
        this.setState({
            sErrName: this.validateText(e.target.firstName.value, "First Name", 2),
            sErrEmail: this.validateEmail(e.target.submit_by.value, "Email"),
            sErrLastName: this.validateText(e.target.lastName.value, "Last Name", 2),
            sErrPhone: this.validateText(e.target.phone.value, "Phone No", false),
            sErrBoxComment: this.validateText(e.target.commentbox.value, "Enquiry", 10)
        }, () => {
            if($.trim(this.checkErrorMsgStatus()) !== "")
            {
                return false;
            }
            else
            {
                // Normally we would use return true, but now we just wanna say that everything is okay
                this.sendEmail();
            }
        });

    }

    // Send an email through our Email server
    sendEmail(){
        let parentThis = this;
        let endpoint = 'https://beta.qds.quickie-divorce.com/api/client-lead';
        let objFrmMain = document.contact;

        // send customer request to QDS
        let workload = {
            'domain': 'cohabitation-agreement.co.uk',
            'email': objFrmMain.submit_by.value,
            'first_name': objFrmMain.firstName.value,
            'last_name': objFrmMain.lastName.value,
            'phone': objFrmMain.phone.value,
            'callback': null,
            'notes': objFrmMain.commentbox.value
        };

        $.ajax({
            url: endpoint,
            method: "POST",
            data: workload
        }).fail(function(){
            parentThis.state.sGeneralMsg = "Probably the message could not be sent for unknown reasons! Please, try later and if the problem  persists contact the consumer service.";
            parentThis.modalReport();
        }).done(function(result){
            if(result.success === true){
                parentThis.state.sGeneralMsg = "Message sent. You will be contacted as soon as possible.";
                parentThis.modalReport();
                $("[name='contact']").trigger("reset");
            } else {
                parentThis.state.sGeneralMsg = "Message not sent for unknown reasons! Please, try later and if the problem  persists contact the consumer service.";
                parentThis.modalReport();
            }
        });
    }

    countChars(field_this){
        if(field_this.target.value.length > 244){
            this.setState({sErrBoxComment: "You have reached the chars limit!"});
        } else {
            this.setState({sErrBoxComment: ""});
        }
    }

    clearAll(){
        this.setState({
            sErrName: "",
            sErrEmail: "",
            sErrLastName: "",
            sErrPhone: "",
            sErrBoxComment: ""
        });
    }

    render() {
        return (
            <div className="content_wrapper p-4">
                <MetaTags>
                    <title>Contact us</title>
                    <meta id="meta-description" name="description" content="Contact us via email, phone our FREE advice line or write to our address. Fill in our online questionnaire to have your Cohabitation Agreement completed by a legal expert.  " />
                    <meta id="meta-keywords" name="keywords" content="Cohabitation Agreement, DIY Agreement, Quick Cohabitation, Contact Us, Online Cohabitation Agreement, Cohabitation Advice, Cohabitation  Information, Cheap Agreement, FREE Advice, Email Advice" />
                    <meta id="og-title" property="og:title" content="Contact us" />
                    <meta id="og-image" property="og:image" content="../images/couple.fc414c90.jpg"/>
                </MetaTags>
                <div className="row">
                    <div className="col-md-8 col-sm-12">
                        <h1>Contact Us</h1>
                        <img src="/images/title_contact.gif" alt="" />
                        <p>Complete the form below to request more information.</p>
                        <p>Please complete all fields.</p>
                        <form name="contact" method="post" action="" onSubmit={this.validate.bind(this)} >
                            <div className="form-group">
                                <label htmlFor="yourFirstName">First name
                                    </label>
                                <input type="text" className="form-control form-control-sm" name="firstName" id="firstName" maxLength="50"
                                       aria-describedby="clientFirstName" placeholder="Enter your first name" />
                                {
                                    this.state.sErrName && (
                                        <span className="error-box p-1 ">{this.state.sErrName}</span>
                                    )}
                            </div>
                            <div className="form-group">
                                <label htmlFor="yourLastName">Last name

                                </label>
                                <input type="text" className="form-control form-control-sm" name="lastName" id="lastName" maxLength="50"
                                       aria-describedby="clientLastName" placeholder="Enter your last name" />
                                {
                                    this.state.sErrLastName && (
                                        <span className="error-box p-1">{this.state.sErrLastName}</span>
                                    )}
                            </div>
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">Email address

                                </label>
                                <input type="email" className="form-control form-control-sm" name="submit_by" id="submit_by" maxLength="50"
                                       aria-describedby="emailHelp" placeholder="Enter email" />
                                {
                                    this.state.sErrEmail && (
                                        <span className="error-box p-1">{this.state.sErrEmail}</span>
                                    )}
                            </div>
                            <div className="form-group">
                                <label htmlFor="phoneNumber">Phone number

                                </label>
                                <input type="text" name="phone" className="form-control form-control-sm" id="phone" maxLength="50"
                                       aria-describedby="phoneNumber" placeholder="Phone number" />
                                {
                                    this.state.sErrPhone && (
                                        <span className="error-box p-1">{this.state.sErrPhone}</span>
                                    )}
                            </div>
                            <div className="form-group">
                                <label htmlFor="exampleFormControlTextarea1">Enter your enquiry here:
                                    </label>
                                <textarea className="form-control" onKeyUp={(e) => this.countChars(e)} maxLength="246" name="commentbox" id="exampleFormControlTextarea1" rows="3"></textarea>
                                {
                                    this.state.sErrBoxComment && (
                                        <span className="error-box p-1">{this.state.sErrBoxComment}</span>
                                    )}
                            </div>
                            <button type="submit" className="submit btn btn-primary float-left mr-2"></button>
                            <button type="reset" onClick={this.clearAll.bind(this)} className="btn btn-outline-info btn-sm float-left mr-2 reset-btn">Clear</button>
                        </form>
                    </div>
                    <div className="col-md-4 col-sm-12">
                        <p className="header"><strong><u>Administration Centre:</u></strong></p>
                        <p>Fields House<br/>
                            12/13 Old Fields Road<br />
                            Pencoed<br />
                            Bridgend<br />
                            CF35 5LJ
                        </p>
                        <p className="space"><strong>Tel: </strong>0800 440 2512</p>
                        <p className="space"><strong>Fax: </strong>01656 673 361</p>
                        <p className="header">General Enquiries:</p>
                        <a href="mailto:enquiries@cohabitation-agreement.co.uk" className="BotNav">enquiries@cohabitation-agreement.co.uk</a>
                        <p className="header">Customer Support:</p>
                        <a href="mailto:support@cohabitation-agreement.co.uk" className="BotNav">support@cohabitation-agreement.co.uk</a>
                        <p className="header">Comments &amp; Suggestions:</p>
                        <a href="mailto:webmaster@cohabitation-agreement.co.uk" className="BotNav">webmaster@cohabitation-agreement.co.uk</a>
                    </div>
                </div>
            </div>
        );
    }
}

export default Contact;