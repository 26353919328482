import React from "react";
import Button from "../../components/Button";
import ContentRight from "../../components/ContentRight";
import MetaTags from "react-meta-tags";


function Faq() {

    return (
        <div className="content_wrapper">
            <MetaTags>
                <title>FAQ</title>
                <meta id="meta-description" name="description"
                      content="Frequently asked questions from Cohabitation Agreement UK’s existing customers."/>
                <meta id="meta-keywords" name="keywords"
                      content="Cohabitation Agreement, DIY Cohabitation Agreement, Quick Agreement, Frequently Asked Questions, Online Agreement, Cohabitation Agreement Advice, FAQ"/>
                <meta id="og-title" property="og:title" content="FAQ"/>
                <meta id="og-image" property="og:image" content="../images/couple.jpg"/>
            </MetaTags>
            <div className="main_left col-md-9 col-sm-12">
                <h1>Frequently Asked Questions</h1>
                <dl className="row">
                    <dt className="col-sm-3"> Can we make an agreement on what property we obtain if we separate?
                    </dt>
                    <dd className="col-sm-9">Yes, an agreement can be made before, during and after the relationship.
                        Financial Agreements under the Family Law Act can only be used if you marry. Cohabitation
                        Agreements only apply to unmarried relationships.
                    </dd>
                    <dt className="col-sm-3"> What can an agreement before or during a relationship do for us?
                    </dt>
                    <dd className="col-sm-9">Your Agreement can say: - How you and your partner want to deal with the
                        financial arrangements in your relationship; and - What will happen to your finances and assets
                        if the relationship ends. This can include dealing with issues of spousal maintenance.
                    </dd>
                    <dt className="col-sm-3">What if we want to end the agreement early?</dt>
                    <dd className="col-sm-9">You can bring your cohabitation agreement to an end but you will both need
                        to agree, sign and meet other formalities.
                    </dd>
                    <dt className="col-sm-3">Can we just make our own agreement?</dt>
                    <dd className="col-sm-9">Informal agreements are not binding. It is always good to reach agreement
                        but you will need to meet the strict formalities for your agreement to be binding. If you have
                        gone to the trouble of reaching agreement it is essential that you formalise what you have
                        agreed and both be certain of where you stand for the future.
                    </dd>
                    <dt className="col-sm-3">What if we have had children since we made our agreement?</dt>
                    <dd className="col-sm-9">If there has been a material change in circumstances relating to children,
                        and the terms of your agreement mean that you, your partner or the children will suffer hardship
                        then the agreement may not be enforced.
                    </dd>
                    <dt className="col-sm-3">Why are you so cheap?</dt>
                    <dd className="col-sm-9">We are an expert online Legal service - therefore we have considerably
                        fewer overheads than a high street solicitor - this keeps our costs down to a minimum, allowing
                        us to pass on our savings to our customers.
                    </dd>
                    <dt className="col-sm-3">What is WorldPay?</dt>
                    <dd className="col-sm-9">WorldPay is the online division of Royal Bank of Scotland, voted "UK's Best
                        Bank" 2005, and uses military-strength encryption and secure servers. This system has never
                        failed or been compromised, and is far safer than over-the-phone transactions.
                    </dd>
                </dl>

                <div className="index_btn1">
                    <Button imageName="order_now3a" imagePath="images/BTN-order-now.gif"
                            imagePathRestore="images/BTN-order-now-over.gif"
                            alt="Order Now" to="ordernow.html"/>
                </div>
                <a href="services.html">
                    <div className="index_btn2"></div>
                </a>
            </div>
            <ContentRight/>
        </div>
    );
}

export default Faq;