import React from "react";
import ContentRight from "../../components/ContentRight";
import MetaTags from "react-meta-tags";

function Terms() {
    return (
        <div className="content_wrapper terms">
            <MetaTags>
                <title>Terms and Conditions</title>
                <meta id="meta-description" name="description" content="The Terms and Conditions must be accepted without limitation or qualification by the customer before using the Prenuptial Agreement UK website and services.  " />
                <meta id="meta-keywords" name="keywords" content="Prenuptial Agreement, DIY Prenuptial, Quick Prenuptial, Terms and Conditions, Agreement Terms, Agreement Conditions, Online Prenuptial Agreement, Prenuptial Advice, Agreement Information, prenup" />
                <meta id="og-title" property="og:title" content="Terms and Conditions" />
            </MetaTags>
            <div className="main_left col-md-9 col-sm-12">
                    <h1>Terms &amp; Conditions</h1>

                    <h3>Terms and Conditions Agreement</h3>

                    <p>Cohabitation Agreement UK is a trading title of Quickie Divorce Limited.</p>

                    <p>This agreement governs the use of the Cohabitation Agreement UK website, including all associated
                        and incidental services provided by Cohabitation Agreement UK resulting from the use of this
                        site.</p>

                    <p>Cohabitation Agreement UK reserves the right, at its discretion, to change or modify all or any
                        part of this Agreement at any time, effective immediately upon notice published on the website.
                        Your use of the Cohabitation Agreement UK website constitutes your binding acceptance of these
                        terms and conditions, including any changes or modifications made by Cohabitation Agreement UK
                        as permitted above.</p>

                    <h3>Information Guide &amp; Forms</h3>

                    <p>You may not publish, transmit, transfer or sell, reproduce, create derivative works from,
                        distribute, perform, display, or in any way exploit any of the Content, in whole or in part,
                        except as expressly permitted in this Agreement.</p>

                    <p>You may download or copy the information and advice contained on the site for your own personal
                        use only, provided that you maintain all copyright and other notices contained in such
                        Content.</p>

                    <p>In no event shall Cohabitation Agreement UK be liable for any indirect, incidental, special or
                        consequential damages arising out of or in any way connected with the use of the documents, this
                        website or the support service whether based in contract, tort, strict liability or otherwise,
                        except in relation to death or personal injury for which no limit shall apply. The liability of
                        Cohabitation Agreement UK for direct loss arising out of the use of the documents, this website,
                        and the support service whether based in contract, tort, strict liability or otherwise is
                        limited to the total value of the transactions under which the claim arises for any one event or
                        series of connected events.</p>

                    <p>Every endeavour will be taken to ensure that the service is updated regularly to ensure its
                        accuracy. It is our belief that our service provides clients with the necessary legal documents
                        and information to complete their agreement.</p>

                    <h3>Statutory Rights</h3>

                    <p>These terms and conditions do not affect your statutory rights.</p>

                    <h3>Support</h3>

                    <p>Cohabitation Agreement UK will provide technical support via email to help you through the
                        process of downloading the forms and begin using them.</p>

                    <p>We will also provide assistance with general queries regarding the completion of the relevant
                        fields of the forms, however we are not legal advisers and are not authorised to provide legal
                        advice.</p>

                    <h3>Cancellation Policy</h3>

                    <p>Clients purchasing the Cohabitation Agreement UK package online will not be eligible to cancel
                        their order as transactions where the computer software is unsealed by the computer immediately
                        on purchase is exempt under the Consumer Protection (Distance Selling) Regulations 2000.</p>

                    <h3>Refund Policy</h3>

                    <p>Any use of our service requires the client to utilize our automated software (where the computer
                        software is ‘unsealed’ by the computer immediately on purchase). All transactions of this type
                        are exempt under the Consumer Protection (Distance Selling) Regulations 2000.</p>

                    <p>Therefore, ALL FEES ARE NON-REFUNDABLE even if you were to change your mind. By using our
                        services and products you agree to our Terms and Conditions.</p>

                    <h3>Customer Care/Complaints</h3>

                    <p>We have always prided ourselves in providing a full customer care service and it is our intention
                        to deal promptly with all complaints that may arise in relation to the service that we provide
                        within 14 days of receipt of the complaint. Normally, replies to any complaint would be made by
                        E-mail or, if required, we can ensure that we can provide a telephone facility to discuss
                        matters of a more complex nature if so required.</p>

                    <h3>Limitations of Use</h3>

                    <p>You may not use the website or the documents for any other purpose except for your own personal
                        use.</p>

                    <p>As a condition of your use of this website, you warrant to us that you will not use this website
                        for any purpose that is unlawful or prohibited by these terms, conditions, and notices.</p>

                    <h3>Limitation of Liability in respect of the Documents</h3>

                    <p>We enclose with this agreement, a disclaimer in respect of our liability in relation to our
                        consent order service. Please ensure that it is read carefully as the following are the terms
                        upon which we would provide our divorce service to you.</p>

                    <h3>Privacy Policy</h3>

                    <p>By purchasing from us, your details will be entered into our customer database. We will never
                        sell or otherwise share your name, email address, or other personal information with any other
                        business or marketing campaign without your permission. We will however, send you information
                        from time to time detailing our product updates and special offers. You may opt-out of receiving
                        these notifications at any time after purchasing by using the unsubscribe link provided. All
                        members' personal data will be saved in accordance with UK and EU privacy laws and the Data
                        Protection Act 1998.</p>

                    <h3>For your information</h3>

                    <p>Please be aware that any customers who use certain E-Mail accounts (e.g. AOL) may receive E-Mails
                        to a ‘bulk folder’ rather than the ‘inbox’.</p>
            </div>
            <ContentRight />
        </div>
        );
}

export default Terms;