import React from "react";
import ContentRight from "../../components/ContentRight";


function Article7() {
    return (
        <div className="content_wrapper">
            <div className="main_left col-md-9 col-sm-12">
                <h1>Get it Right the Second Time After Divorce</h1>

                <p>Four out of ten marriages in UK are successive marriages for one or both the partners. While
                    remarriages are certainly on the increase, the divorce rate for such marriages is also at an
                    alarmingly high rate. For that reason, it is important for people to get it right the second
                    time after divorce in order to avoid the heartbreak and misery all over again. </p>

                <p>Second or third marriages are more complicated than first marriages because there are several
                    monetary issues involved. In addition, most people tend to have children from previous
                    marriages, which can make things even more complex. </p>

                <h3>Things to Keep in Mind When Getting into New Relationships after a Divorce</h3>

                <ul>
                    <li><b>Cohabitation Arrangements</b>
                        <p>Even if you are only cohabiting with your partner before getting married again, make sure
                            that all your assets are in your name. While this may seem unromantic to some, it is
                            important that you protect your assets. </p>

                        <p>If your partner has not made any direct financial contribution towards an asset, they
                            will not have any claim on the assets if you choose to separate. Many people choose to
                            enter into a cohabitation agreement to avoid any problems related to ownership of
                            assets. </p>
                    </li>
                    <li><b>Assets and Liabilities</b>
                        <p>You need to be careful about your assets before you get into another marriage. Dealing
                            with disputed property matters after a divorce can be a cause of concern for many
                            remarried couples. </p>

                        <p>While many people remember to take care of their assets, not many pay attention towards
                            making sure that they are protected from their new partner’s liabilities. Make sure that
                            you are completely aware of your partner’s liabilities. </p>

                        <p>Sometimes, credit card debt can run into thousands of pounds. If that is the case, make
                            sure that you take adequate measures to protect your sole assets from your partner’s
                            liabilities. </p>

                        <p>It is important to bear in mind that even though some of the assets may be in your name,
                            after marriage they become a part of the marital pot and are redistributed equally at
                            the time of divorce. Therefore, if you have children from a previous marriage and wish
                            to protect their financial interests, it would be wise to make financial arrangements
                            accordingly. </p>
                    </li>

                    <li><b>Sign a Prenuptial Agreement</b>
                        <p>Prenups are fast gaining popularity in the UK, even though they are not legally binding.
                            However, many judges are accepting prenuptial arrangements these days if the agreement
                            seems to be fair and reasonable. If you have a considerable asset base, it would make
                            sense to formulate a prenuptial agreement. </p>

                        <p>If you have children from a previous marriage or you have had a bad experience of losing
                            out on significant assets during your divorce, prenups become even more important. </p>
                    </li>

                    <li><b>Issues related to Investments and Spousal Maintenance</b>
                        <p>You will need to discuss your investments and savings with your future partner before
                            getting married. This will give you a fair idea of each other’s financial worth. While
                            this may not seem like a very romantic task to engage in, it is important that you and
                            your partner undertake this exercise seriously and honestly. </p>

                        <p>With respect to spousal maintenance, unless you and your partner agreed upon a “clean
                            break”, any amount that you receive as spousal maintenance will stop once you decide to
                            marry again. Therefore, if spousal maintenance forms an integral part of your financial
                            resources, make sure that your new partner is willing to provide similar financial
                            support to you. </p>
                    </li>
                </ul>

                <h3>The Final Word</h3>

                <p>Marrying someone again can mark a beginning of a special new life for you. While it is important
                    to bask in your newfound happiness, it is equally important to make sure that you protect
                    yourself from any unforeseen event. Therefore, make sure that you engage in honest and open
                    communication with your future partner before marriage so that you can avoid any complication at
                    a later stage. </p>
            </div>
            <ContentRight />
        </div>
    );
}

export default Article7;