import React from "react";
import {Link} from "react-router-dom";

function Footer() {
    return (
        <footer className="footer clearfix text-center" id="footer">
            <div className="row" id="footer_wrapper">
                <div className="col-md-3 col-sm-6">
                    <Link to="wills.html" className="">
                        <img src="images/special-offer.gif" alt="special offer" border="0" />
                    </Link>
                </div>
                <div className="col-md-3 col-sm-6">
                    <Link to="free-ebook.html" className="">
                        <img src="images/free-ebook.gif" alt="free ebook" border="0" />
                    </Link>
                </div>
                <div className="col-md-3 col-sm-6">
                    <Link to="testimonials.html" className="">
                        <img src="images/feedback.gif" alt="feedback" border="0"/>
                    </Link>
                </div>
                <div className="col-md-3 col-sm-6">
                    <Link to="guarantee.html" className="">
                        <img src="images/risk-free.gif" alt="risk free" border="0" />
                    </Link>
                </div>
            </div>
            <div className="foot_contact_us">
                <p>Confused About Cohabitation? Call <span>01656 508 262</span> or drop us a message.</p>
                <Link className="" to="/contact.html">Contact Us</Link>
            </div>
            <div className="footer_logo">
                <img src="images/logo.gif" alt="separation agreement" border="0"/>
            </div>
            <div className="footer_links">
                <div className="footer_links_items">
                    <h4>GETTING STARTED</h4>
                    <ul>
                        <li><Link to="privacy-policy.html">privacy policy</Link></li>
                        <li><Link to="disclaimer.html">disclaimer</Link></li>
                        <li><Link to="terms.html">terms and conditions</Link></li>
                        <li><Link to="sitemap.html">sitemap</Link></li>
                        <li><Link to="sitemap.html"><img src="images/xml.gif" alt="sitemap" /></Link></li>
                    </ul>
                </div>
                <div className="footer_links_items">
                    <h4>HELP &amp; ADVICE</h4>
                    <ul>
                        <li><Link to="privacy-policy.html">privacy policy</Link></li>
                        <li><Link to="disclaimer.html">disclaimer</Link></li>
                        <li><Link to="terms.html">terms and conditions</Link></li>
                        <li><Link to="sitemap.html">sitemap</Link></li>
                        <li><Link to="sitemap.html"><img src="images/xml.gif" alt="sitemap" /></Link></li>
                    </ul>
                </div>
                <div className="footer_links_items">
                    <h4>ABOUT US</h4>
                    <ul>
                        <li><Link to="privacy-policy.html">privacy policy</Link></li>
                        <li><Link to="disclaimer.html">disclaimer</Link></li>
                        <li><Link to="terms.html">terms and conditions</Link></li>
                        <li><Link to="sitemap.html">sitemap</Link></li>
                        <li><Link to="sitemap.html"><img src="images/xml.gif" alt="sitemap" /></Link></li>
                    </ul>
                </div>
            </div>
            <div className="foot_copy clearfix">
                <p className="copyright">&nbsp;© Cohabitation Agreement UK 1999-2021</p>
            </div>
        </footer>
    );
}

export default Footer;