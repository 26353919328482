import React from "react";
import Button from "../../components/Button";
import ContentRight from "../../components/ContentRight";
import MetaTags from "react-meta-tags";

function Testimonials() {
    return (
        <div className="content_wrapper">
            <MetaTags>
                <title>Testimonials</title>
                <meta id="meta-description" name="description" content="What our customers had to say about our Pre Nuptial Agreements and our marital separation service " />
                <meta id="meta-keywords" name="keywords" content="Prenuptial Agreement, DIY Prenuptial Agreement, Quick Agreement, Frequently Asked Questions, Online Agreement, Prenuptial Agreement Advice, FAQ, prenup, testimonials" />
                <meta id="og-title" property="og:title" content="Testimonials" />
            </MetaTags>
            <div className="main_left col-md-9 col-sm-12">
                <h1>Testimonials</h1>

                <div className="index_btn1">
                    <Button imageName="order_now3a" imagePath="images/BTN-order-now.gif" imagePathRestore="images/BTN-order-now-over.gif"
                            alt="Order Now" to="ordernow.html" />
                </div>
            </div>
            <ContentRight />
        </div>
        );
}

export default Testimonials;