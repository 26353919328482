import React from "react";
import ContentRight from "../../components/ContentRight";


function Article6() {
    return (
        <div className="content_wrapper">
            <div className="main_left col-md-9 col-sm-12">
                <h1>Settlement in Divorce</h1>

                <h3></h3>

                <p>When most people think about divorce, their main concern is the divorce settlement. There are
                    times when a divorcing couple takes only a few weeks to settle their divorce. However, this
                    situation is only feasible when a couple agrees to part amicably, there are no children
                    involved, and they have very few marital assets or liabilities to divide. When couples are
                    unable to separate amicably, the financial settlement issues can get ugly. </p>

                <h3>Financial Settlement and Legal Advice</h3>

                <p>No matter how hard couples try, it can be tough to reach an amicable financial settlement. If you
                    and your spouse have considerable financial assets, you are bound to experience rifts as far as
                    division of assets and liabilities is concerned. For that reason, it is imperative that you seek
                    legal advice. </p>

                <p>Even if you think that you and your partner can reach a sensible settlement with minimal
                    animosity, it would still make sense to hire a solicitor to know the legal nitty-gritty of your
                    divorce process. </p>

                <p></p>

                <h3>Working Out a Financial Settlement</h3>

                <p>Financial settlement has many aspects and is not only about the division of the family home.
                    Factors such as pensions must be taken into account because these are extremely valuable. In
                    addition, there might be certain assets that you can claim and which you need to be aware
                    of.</p>

                <p>When you and your partner are completely aware of what is in the ‘pot’, you can consider how to
                    trade off one asset for another of equal value as opposed to complicating things by dividing
                    each and every asset individually. </p>

                <p>While many people think that financial settlement is a 50:50 split, in reality it is much more
                    complicated than that. Therefore, always discuss your options with your solicitor. </p>

                <h3>Child Maintenance </h3>

                <p>Child maintenance issues play a very important role in the settlement process. The maintenance
                    payments will be agreed through the Child Support Agency (CSA) or the court if both the partners
                    cannot reach an agreement about the maintenance payment amount until the child turns 17. </p>

                <p>Many people hesitate in getting the CSA involved. However, it is important to remember that
                    courts often ask high earners to pay far more than the amount usually determined by the
                    CSA. </p>

                <h3>Spousal Maintenance </h3>

                <p>The spousal maintenance amount usually depends on the needs of each person in terms of how much
                    money a person has to meet their needs in comparison to the projected household expenses. </p>

                <p>If one of the partners is working whereas the other does not, it changes financial circumstances
                    considerably. There are times when the partner who pays the maintenance loses their job or
                    simply cannot afford to pay the maintenance amount. In such cases, the maintenance level can be
                    varied by the court. </p>

                <p>It is important to know that the spousal maintenance payments end if the receiving partner
                    remarries. However, the payments do not end if the receiving partner cohabitates with another
                    partner. </p>

                <h3>Inheritances</h3>

                <p>When the assets are divided in a divorce, both you and your partner will not only be subject to
                    losing your assets it is possible you will lose your inheritance. Not many people are aware of
                    the significance of inheritances until they lose them, dividing up the assets of the marriage.
                    However, it is possible for you to keep your inheritance, but only if you can prove your partner
                    would not suffer financially without it.</p>
            </div>
            <ContentRight />
        </div>
    );
}

export default Article6;