import React from "react";
import ContentRight from "../../components/ContentRight";


function Article4() {
    return (
        <div className="content_wrapper">
            <div className="main_left col-md-9 col-sm-12">
                <h1>Cohabiting and Same Sex Couples</h1>

                <p>Same-sex couples may have recently become eligible for marriage in some countries, however if the
                    couples home country does not recognise same-sex marriages the marriage is likely to be void
                    within that country. </p>

                <p>There are over four million cohabiting couples in England and Wales. In recent years gay couples
                    have received more rights when it comes to cohabitation. In 2005 couples who have entered into a
                    civil partnership were offered the same rights as married couples. Same sex couples have now
                    become entitled to next-of-kin status on property, tax and pensions. The fact that approximately
                    25% of gay male couples and 30% of gay female couples have children further supports the
                    recommendation that same-sex couples enter into a cohabitation agreement to cover child custody
                    issues in the event of a break up. A cohabitation agreement is recommended not only for
                    cohabiting couples but also for couples who have entered into a civil partnership. A
                    cohabitation agreement is similar to a prenuptial agreement which outlines the arrangements for
                    the outcome of the civil partnership should the relationship dissolve. </p>

                <p>However, there are still many differences between marriage and civil partnerships which gay
                    couples have been campaigning against in a bid for legal recognition. Same sex couples are
                    required to prove their commitment to one another before they are permitted to register their
                    partnership. It is likely that a term will be imposed, requiring same-sex couples to show that
                    they have been entered into a relationship for two years before being eligible to register a
                    partnership. Cohabitation agreements and Wills will be essential for same sex couples to protect
                    their security in the event of a break up.</p>

                <p>Gay couples may be in an even more vulnerable position than other couples whilst cohabiting.
                    Therefore, entering into a cohabitation agreement is wisely advised to clarify their
                    arrangements for mutual financial support, dealing with debts and looking after children
                    etc. </p>
            </div>
            <ContentRight />
        </div>
    );
}

export default Article4;