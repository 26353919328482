import React, {useState} from "react";
import Button from "../../components/Button";
import ContentRight from "../../components/ContentRight";
import {Popover, PopoverHeader, PopoverBody} from "reactstrap";
import MetaTags from "react-meta-tags";


function PersonalService() {

    const [popOvervalues, setPopOverValues] = useState({
        pop_over_open: false,
        popover_header: "",
        popover_message: "",
        textarea_value: "J1 New Road, Newtown"
    });

    const togglePopover = () => {
        popOvervalues.pop_over_open = !popOvervalues.pop_over_open;

    }

    const popOver = (primo, secondo) => {
        setPopOverValues({
            ...popOvervalues,
            popover_header: primo,
            popover_message: secondo,
            pop_over_open: true
        });
    }


    // close all popover
    const closeAllPopover = () => {
        setPopOverValues({
            pop_over_open: false
        });
    }

    return (
        <div className="content_wrapper">
            <MetaTags>
                <title>Personalised service</title>
                <meta id="meta-description" name="description"
                      content="Have your Cohabitation Agreement drafted by an experienced expert using your information. Simply complete our online questionnaire"/>
                <meta id="meta-keywords" name="keywords"
                      content="Cohabitation Agreement, Personalised Service, Quick Cohabitation, FREE Cohabitation Advice, Online Cohabitation, Cohabitation Agreement Experts, Cohabitation Agreement Advice, Cohabitation Agreement Information"/>
                <meta id="og-title" property="og:title" content="Personalised service"/>
                <meta id="og-image" property="og:image" content="../images/couple.fc414c90.jpg"/>
            </MetaTags>
            <div className="main_left col-md-9 col-sm-12">
                <h1>Personalised Service</h1>

                <p>Having a Cohabitation Agreement put in place could not be simpler. Just answer a few quick questions to
                    have your case assessed by our experienced staff and your agreement will be drafted for you. Our
                    FREE phone number is available to answer any questions that you might have from 9am to 9pm. </p>

                <p>No other online Cohabitation Agreement service allows you the chance to compare quality or value for
                    money and ultimately get the lowest cost, highest performance deal.</p>

                <p>Order yours now and get all this for just &pound;67:</p>

                <ul>
                    <li><b>FREE Support</b> – help and advice on any related topic over the phone or via email</li>
                    <li><b>FREE Updates</b> – notification of law changes or requirements</li>
                    <li><b>FREE Guide</b> – <i>The Essential Guide to Cohabitation Agreements</i> (RRP £19.99)</li>
                    <li><b>Discounted Will </b>(Just £10) – show your loved ones they will be provided for</li>
                    <li><b>GUARANTEE</b> – no-quibble, 100% money-back guarantee</li>
                </ul>

                <p>If you purchase online using a debit or credit card today we will provide you with a Discounted Will
                    for just &pound;10 (usually &pound;39).</p>

                <p><b>GUARANTEE</b> – no-quibble, 100% money-back guarantee </p>

                <p>You won’t need the services of a local high street solicitor, or a court appearance, and you don’t
                    need
                    to fill in complicated legal forms or understand solicitors’ jargon; all you need are your personal
                    details and a few minutes of your time – your Cohabitation Agreement can be completed for you, then
                    you
                    can have them sent to you.</p>

                <div className="order_btn1">
                    <Button imageName="order_now3a" imagePath="images/BTN-order-now.gif"
                            imagePathRestore="images/BTN-order-now-over.gif"
                            alt="Order Now" to="ordernow.html"/>
                </div>

                <hr/>

                <h3>Preview Our Questionnaire</h3>

                <p>To automatically produce a completed Cohabitation Agreement you only need to fill in a simple
                    online questionnaire.</p>

                <p>Click on [help] for an explanation of how to answer the relevant question.</p>

                <p>Below is an example of our simple, easy-to-use questionnaire:</p>

                <div className="example_qnnaire_border">
                    <table className="qnnaire" width="100%" align="center" border="0" cellPadding="13"
                           cellSpacing="1">
                        <tbody>
                        <tr>
                            <td className="backcell22">What is the name of the first party?</td>
                            <td className="backcell22"><img src="images/right.gif" alt="right gif"/></td>
                            <td className="backcell4" align="center">
                                <input name="textfield" value="John Smith" className="form-control" size="28"
                                       maxLength="30" readOnly type="text"/></td>
                            <td className="backcell22b">
                                <a href="#/" id="mypopover" onMouseOut={() => closeAllPopover()}
                                   onClick={() => popOver("Help Info", "Please enter full name")}
                                   className="popoverBox">
                                    [help]</a>
                            </td>
                        </tr>
                        <tr>
                            <td className="backcell22">What is the address of the first party?</td>

                            <td className="backcell22"><img src="images/right.gif" alt="right gif"/></td>
                            <td className="backcell4" align="center">
                                <textarea name="textarea" rows="3" className="form-control" readOnly
                                          defaultValue={popOvervalues.textarea_value}></textarea>
                            </td>
                            <td className="backcell22b">
                                <a href="#/" id="mypopover" onMouseOut={() => closeAllPopover()}
                                   onClick={() => popOver("Help Info", "Please enter the current/previous address")}
                                   className="popoverBox">[help]</a>
                            </td>
                        </tr>
                        <tr>
                            <td className="backcell22">What is the name of the second party?</td>
                            <td className="backcell22"><img src="images/right.gif" alt="right gif"/></td>
                            <td className="backcell4" align="center">
                                <input name="textfield3" className="form-control" value="Joan Roberts" size="28"
                                       maxLength="30" type="text" readOnly/></td>
                            <td className="backcell22b">
                                <Popover
                                    placement="top"
                                    isOpen={popOvervalues.pop_over_open}
                                    target="mypopover"
                                    toggle={togglePopover}>
                                    <PopoverHeader>{popOvervalues.popover_header}</PopoverHeader>
                                    <PopoverBody>
                                        {popOvervalues.popover_message}
                                    </PopoverBody>
                                </Popover>
                                <a href="#/" id="mypopover" onMouseOut={() => closeAllPopover()}
                                   onClick={() => popOver("Help Info", "Please enter full name")}
                                   className="popoverBox">[help]</a>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <p>You can complete the questionnaire in minutes or simply click the ‘save’ button and return to
                    complete it at your own pace and in your own time.</p>

                <p>Once you have filled in the questionnaire the information is used to draft a legally-binding
                    Cohabitation Agreement document which will then be sent to you by email within 2-3 working
                    days.</p>

                <div className="order_btn1">
                    <Button imageName="order_now3a" imagePath="images/BTN-order-now.gif"
                            imagePathRestore="images/BTN-order-now-over.gif"
                            alt="Order Now" to="ordernow.html"/>
                </div>

                <hr/>

                <h3>Price Comparison Chart</h3>

                <p>You only want to make one Cohabitation Agreement, so choose <b>the highest quality, most
                    comprehensive, value-for-money online solution available in the UK</b>. Compare other leading
                    services and their prices:</p>

                <div className="table-container">
                    <table className="compare" border="0" cellPadding="2" cellSpacing="1" align="center">
                        <tbody>
                        <tr align="right">
                            <th className="comparison_table_ths" width="25%">Services</th>
                            <th className="comparison_table_ths" width="15%">High Street<br/> Solicitor</th>
                            <th className="comparison_table_ths" width="15%">PreNuptial.
                                <br/> Agreements.co.uk</th>
                            <th className="comparison_table_ths" width="15%">Divorce<br/> Online</th>
                            <th className="comparison_table_ths" width="15%">Click<br/> Docs</th>
                            <th className="comparison_table_ths" width="15%">Our<br/> Service</th>
                        </tr>

                        <tr>
                            <td className="bar">Completed Online</td>
                            <td className="main"><span className="cross">x</span></td>
                            <td className="main"><span className="tick"></span></td>
                            <td className="main"><span className="cross">x</span></td>
                            <td className="main"><span className="cross">x</span></td>
                            <td className="green"><span className="tick1"></span></td>
                        </tr>
                        <tr>
                            <td className="bar">Supervised Solicitor<br/>Service</td>
                            <td className="main"><span className="tick"></span></td>
                            <td className="main"><span className="tick"></span></td>
                            <td className="main"><span className="cross">x</span></td>
                            <td className="main"><span className="cross">x</span></td>
                            <td className="green"><span className="tick1"></span></td>
                        </tr>
                        <tr>
                            <td className="bar">FREE Phone Support</td>
                            <td className="main"><span className="cross">x</span></td>
                            <td className="main"><span className="tick"></span></td>
                            <td className="main"><span>9am – 9pm</span></td>
                            <td className="main"><span className="cross">x</span></td>
                            <td className="green"><span>9am – 9pm</span></td>
                        </tr>
                        <tr>
                            <td className="bar">FREE Updates</td>
                            <td className="main"><span className="cross">x</span></td>
                            <td className="main"><span className="tick"></span></td>
                            <td className="main"><span className="cross">x</span></td>
                            <td className="main"><span className="cross">x</span></td>
                            <td className="green"><span className="tick1"></span></td>
                        </tr>
                        <tr>
                            <td className="bar">FREE Consultation</td>
                            <td className="main"><span className="cross">x</span></td>
                            <td className="main"><span className="tick"></span></td>
                            <td className="main"><span className="cross">x</span></td>
                            <td className="main"><span className="cross">x</span></td>
                            <td className="green"><span className="tick1"></span></td>
                        </tr>
                        <tr>
                            <td className="bar">FREE eBook</td>
                            <td className="main"><span className="cross">x</span></td>
                            <td className="main"><span className="tick"></span></td>
                            <td className="main"><span className="cross">x</span></td>
                            <td className="main"><span className="cross">x</span></td>
                            <td className="green"><span className="tick1"></span></td>
                        </tr>
                        <tr>
                            <td className="bar">Discounted Will(s)</td>
                            <td className="main"><span className="cross">x</span></td>
                            <td className="main"><span className="cross">x</span></td>
                            <td className="main"><span className="tick"></span></td>
                            <td className="main"><span className="cross">x</span></td>
                            <td className="green"><span className="tick1"></span></td>
                        </tr>
                        <tr>
                            <td className="bar">Guarantee</td>
                            <td className="main"><span className="cross">x</span></td>
                            <td className="main"><span className="cross">x</span></td>
                            <td className="main"><span className="cross">x</span></td>
                            <td className="main"><span className="cross">x</span></td>
                            <td className="green"><span className="tick1"></span></td>
                        </tr>
                        <tr>

                            <td className="costb">Costs</td>
                            <td className="cost">£1000+</td>
                            <td className="cost">£350.00</td>
                            <td className="cost">£75.00</td>
                            <td className="cost">£46.06</td>
                            <td className="costg"><span className="white"><strong>£67</strong></span></td>
                        </tr>

                        </tbody>
                    </table>
                </div>


                <p>You can see from the above comparison chart that Cohabitation Agreement UK
                    offers <b>The UK's Premier Service</b>. Cohabitation Agreement UK are an expert online
                    legal service - having considerably fewer overheads than a high-street Solicitor - this
                    keeps costs down to a minimum, allowing savings to be passed on to you, the customer.
                </p>

                <div className="order_btn1">
                    <Button imageName="order_now3a" imagePath="images/BTN-order-now.gif"
                            imagePathRestore="images/BTN-order-now-over.gif"
                            alt="Order Now" to="ordernow.html"/>
                </div>

                <hr/>

                <h3>See What You Can Save By Using Us</h3>

                <p>A High Street Solicitor may charge you £195 per hour (plus VAT)</p>

                <ul>
                    <li>Solicitor Fees (incl. VAT) &pound;2375</li>
                    <li>£300 court fees</li>
                    <li>Total costs = <b>£2675</b></li>
                </ul>

                <p>Compare the cost of our <b>Cohabitation Agreement</b>:</p>

                <ul>
                    <li>Our Cohabitation Agreement: £67</li>
                    <li>No court fees required</li>
                    <li>Total Costs £67</li>
                </ul>

                <p>That’s a <b>TOTAL SAVING OF £2608</b> when using our service!</p>

                <p><b>PLUS</b> – Buy any of our Cohabitation Agreement options by credit or debit card now
                    and we’ll give you a new Will for just £10 (normally) £39 with FREE updates for
                    life. </p>

                <p><b>ALSO</b> – Receive “The Essential Guide to Cohabitation Agreements” by Jayne McGill
                    (normally £19.99)</p>

                <p><b>ABSOLUTELY FREE</b></p>

                <p><b>Start your Cohabitation Agreement</b> by clicking the “order now” button...</p>

                <div className="order_btn1">
                    <Button imageName="order_now3a" imagePath="images/BTN-order-now.gif"
                            imagePathRestore="images/BTN-order-now-over.gif"
                            alt="Order Now" to="ordernow.html"/>
                </div>
            </div>
            <ContentRight/>
        </div>
    );
}

export default PersonalService;